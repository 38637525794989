import { Injectable } from '@angular/core';
import { Language } from '../enums/language.enum';

@Injectable()
export class Constants {
    public static readonly LANGUAGE = 'language';
    
    public static readonly ERRORS = 'ERRORS';

    public static readonly rowCounts = [
        {count: 10}, 
        {count: 25}, 
        {count: 50}, 
        {count: 100},
    ];

    public static readonly languages = [
        {name: 'ENGLISH', value: Language.EN},
    ];

    public static days = [
      {key: "mon", value: 'Mondays'},
      {key: "tue", value: 'Tuesdays'},
      {key: "wed", value: 'Wednesdays'},
      {key: "thu", value: 'Thursdays'},
      {key: "fri", value: 'Fridays'},
      {key: "sat", value: 'Saturdays'},
      {key: "sun", value: 'Sundays'},
      {key: "sd", value: 'Special Days'},
      {key: "bh", value: 'Bank Holidays'},
    ];
}
