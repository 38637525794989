export class CapCharge {
  id: number;
  length: number;
  cost: number;
  parsed_cost: string;
  parsed_length: string;

  constructor(capCharge) {
    if (capCharge) {
      this.id = capCharge.id;
      this.length = capCharge.length;
      this.cost = capCharge.cost;
      this.parsed_cost = this.parseCost(capCharge.cost);
      this.parsed_length = this.parseRateTime(capCharge.length);
    }
  }

  parseCost(x: string) {
    let num = parseInt(x) * .01;
    return num.toFixed(2);
  }

  parseRateTime(x: string) {
    let num = parseInt(x);

    if (num > 1439) {
      let result = 'Over ' + Math.floor(num / 1440) + ' days ';
      if (num % 1440 > 0) {
        return result + Math.floor((num % 1440) / 60) + ' hours';
      }
      return result;
    }

    if (num > 59) {
      let result = 'Over ' + Math.floor(num / 60) + ' hours ';
      if (num % 60 > 0) {
        return result + (num % 60) + ' Minutes';
      }
      return result;
    }

    return 'Over ' + num + ' Minutes';
  }
}
