import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../auth.service';
import swal from "sweetalert2";
import { ChargingPoint } from "../../models/chargingPoint.model";
import { ChargeGroup } from "../../models/chargeGroup.model";

@Injectable({
  providedIn: 'root'
})
export class ChargingPointService {
  user: User;
  token: string;
  options: any;

  constructor(public authService: AuthService, private http: HttpClient) {
    this.token = this.authService.getToken();
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'TOKEN': this.token,
      })
    };
  }

  public addChargingPoint(data: { charge_group_id: number, name: string }): Observable<ChargingPoint> {
    return this.http.post(`${env.veriparkUrl}/api/charging_point`, {data}, this.options).pipe(
      map(resp => {
        return new ChargingPoint(resp['data']);
      }),
      catchError(this.handleError)
    );
  }

  public editChargingPoint(chargingPoint: ChargingPoint, data: { charge_group_id: number, name: string }): Observable<ChargingPoint> {
    return this.http.put(`${env.veriparkUrl}/api/charging_point/${chargingPoint.id}`, {data}, this.options).pipe(
      map(resp => {
        return new ChargingPoint(resp['data']);
      }),
      catchError(this.handleError)
    );
  }


  public getChargingPoint(chargingPoint: ChargingPoint): Observable<[]> {
    return this.http.get(`${env.veriparkUrl}/api/charging_point/${chargingPoint.id}`, this.options).pipe(
      map(resp => {
        return resp['data'];
      }),
      catchError(this.handleError)
    );
  }

  public deleteChargingPoint(chargingPoint: ChargingPoint): Observable<[]> {
    return this.http.delete(`${env.veriparkUrl}/api/charging_point/${chargingPoint.id}`, this.options).pipe(
      map(resp => {
        return resp['data'];
      }),
      catchError(this.handleError)
    );
  }

  getChargingPoints(chargeGroup: ChargeGroup): Observable<Array<ChargingPoint>> {
    const url = `${env.veriparkUrl}/api/charge_group/${chargeGroup.id}/charging_points`;
    return this.http.get<ChargingPoint[]>(url, this.options).pipe(
      map(resp => {
        return resp['data'].map(e => new ChargingPoint(e));
      }),
      catchError(this.handleError)
    );
  }

  public handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      if (error.error) {
        const errors = error.error.errors;
        if (typeof errors === 'object') {
          Object.keys(errors).forEach(function (key) {
            const error = errors[key];
            if (typeof error === 'string') {
              swal.fire('Error', error, 'error').then(r => {
                console.log(r);
              });
            } else {
              if (error.message) {
                swal.fire('Error', error.message, 'error').then(r => {
                  console.log(r);
                });
              }
            }
          });
        }
      }
    }

    return throwError(errorMessage);
  }
}
