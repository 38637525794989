import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ChargeGroup } from "../../../../models/chargeGroup.model";
import { ChargeGroupService } from "../../../../services/customer/chargeGroup.service";
import { ScheduleTariffService } from "../../../../services/customer/scheduleTariff.service";
import { ToastrService } from "ngx-toastr";
import { ScheduleTariff } from "../../../../models/scheduleTariff.model";
import { PowerTariff } from "../../../../models/powerTariff.model";
import { PowerTariffService } from "../../../../services/customer/powerTariff.service";
import { DatePipe } from "@angular/common";
import { TariffService } from "../../../../services/customer/tariff.service";

@Component({
  selector: 'app-edit-schedule-tariff',
  templateUrl: './edit-schedule-tariff.component.html',
  styleUrls: ['./edit-schedule-tariff.component.sass'],
  providers: [DatePipe]
})
export class EditScheduleTariffComponent implements OnInit {

  @Input() scheduleTariff: ScheduleTariff;
  @Input() chargeGroup: ChargeGroup;
  @Output() close = new EventEmitter();
  @Output() refreshScheduleTariffs = new EventEmitter();

  powerTariffs: PowerTariff[];
  scheduleTariffForm: FormGroup;
  chargeGroups: ChargeGroup[];
  originalData: [];

  constructor(
    private powerTariffService: PowerTariffService,
    private tariffService: TariffService,
    private chargeGroupService: ChargeGroupService,
    private scheduleTariffService: ScheduleTariffService,
    private fb: FormBuilder, private toastr: ToastrService,
    public datePipe: DatePipe
  ) {
    this.scheduleTariffForm = this.fb.group({
      'tariff_type': new FormControl('', [Validators.required]),
      'switch_code': new FormControl('', []),
      'default_tariff': new FormControl('', [Validators.required]),
      'start_date': new FormControl('', [Validators.required]),
      'end_date': new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.powerTariffService.getPowerTariffs().subscribe(powerTariffs => {
      this.powerTariffs = powerTariffs;
        this.setOriginalData();
    });
  }

  setOriginalData() {
    this.scheduleTariffService.getScheduleTariff(this.scheduleTariff).subscribe(data => {
      this.originalData = data;

      if (this.originalData['power_tariff_id']) {
        const selectedTariff = this.powerTariffs.find(one => {
          return one.id === this.originalData['power_tariff_id'];
        });
        setTimeout(() => {
          this.scheduleTariffForm.get('tariff_type').setValue('power');
          this.scheduleTariffForm.get('default_tariff').setValue(selectedTariff);
        })
      }

      setTimeout(() => {
        this.scheduleTariffForm.get('switch_code').setValue(data['switch_code']);
        this.scheduleTariffForm.get('start_date').setValue(data['start_date']);
        this.scheduleTariffForm.get('end_date').setValue(data['end_date']);
      }, 0)
    });
  }

  onSubmit(data: []) {
    const params = {
      charge_group_id: this.chargeGroup.id,
      power_tariff_id: data['tariff_type'] === 'power' ? data['default_tariff'].id : null,
      time_tariff_id: data['tariff_type'] === 'time' ? data['default_tariff'].tariff_id : null,
      switch_code: data['switch_code'],
      start_date: this.datePipe.transform(data['start_date'], 'yyyy-MM-dd'),
      end_date: this.datePipe.transform(data['end_date'], 'yyyy-MM-dd'),
    };
    if (params['switch_code'] === "") {
      delete params.switch_code;
    }
    return this.scheduleTariffService.editScheduleTariff(this.scheduleTariff, params).subscribe(() => {
      this.refreshScheduleTariffs.emit();
      this.toastr.success('Schedule tariff has been updated successfully');
      this.close.emit();
    });
  }

  onCancel() {
    this.close.emit();
  }
}
