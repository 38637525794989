import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PrimengService } from "../../../../services/primeng.service";
import { ChargingPoint } from "../../../../models/chargingPoint.model";
import { ChargingPointService } from "../../../../services/customer/chargingPoint.service";
import { ChargeGroup } from "../../../../models/chargeGroup.model";

@Component({
  selector: "app-charging-points-list",
  templateUrl: "./charging-points-list.component.html",
  styleUrls: ["./charging-points-list.component.sass"],
})
export class ChargingPointsListComponent implements OnInit {
  @Input() chargeGroup: ChargeGroup;
  @Output() close = new EventEmitter();
  @Output() refreshChargeGroups = new EventEmitter();

  chargingPoints: ChargingPoint[] = [];
  selectedChargingPoint: ChargingPoint;
  createChargingPoint: boolean;
  editChargingPoint: boolean;
  deleteChargingPoint: boolean;
  loaded: boolean;
  bulkUpload: boolean

  constructor(
    private chargingPointService: ChargingPointService,
    public primeng: PrimengService
  ) {}

  ngOnInit(): void {
    this.setChargingPoints();
  }

  setChargingPoint(chargingPoint: ChargingPoint): void {
    this.selectedChargingPoint = chargingPoint;
  }

  setChargingPoints(): void {
    this.chargingPointService
      .getChargingPoints(this.chargeGroup)
      .subscribe((chargingPoints) => {
        this.chargingPoints = chargingPoints;
        this.loaded = true;
      });
  }

  refreshChargingPoints(): void {
    this.chargingPoints = [];
    this.setChargingPoints();
    this.refreshChargeGroups.emit();
  }

  onCancel() {
    this.close.emit();
  }
}
