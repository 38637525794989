export class LinkedList {
  listId: number;
  listName: string;

  constructor(linkedList) {
    if (linkedList) {
      this.listId = linkedList.list_id;
      this.listName = linkedList.list_name;
    }
  }
}
