import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { PowerTariffService } from "../../../services/customer/powerTariff.service";
import { ToastrService } from 'ngx-toastr';
import { PowerTariff } from "../../../models/powerTariff.model";

@Component({
  selector: 'app-edit-power-tariff',
  templateUrl: './edit-power-tariff.component.html',
  styleUrls: ['./edit-power-tariff.component.sass']
})

export class EditPowerTariffComponent implements OnInit {

  @Input() powerTariff: PowerTariff;
  @Output() close = new EventEmitter();
  @Output() refreshTariffs = new EventEmitter();

  tariffForm: FormGroup;
  originalData: [];
  priceSchedules: boolean;

  constructor(public powerTariffService: PowerTariffService, private fb: FormBuilder, private toastr: ToastrService) {
    this.tariffForm = this.fb.group({
      'name': new FormControl('', Validators.required),
      'dc_kwh_price': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'dc_connection_fee': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'dc_minimum_price': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'dc_maximum_price': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'dc_connection_fee_on_top_of_minimum_price': new FormControl(''),
      'dc_overstay_fee': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'dc_apply_overstay_per_minute': new FormControl(''),
      'dc_overstay_interval': new FormControl('', [Validators.pattern('^[0-9]*$')]),
      'dc_overstay_cap': new FormControl('', [Validators.pattern('^[0-9]*$')]),
      'dc_maximum_charging_session_length_in_minutes': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'ac_kwh_price': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'ac_connection_fee': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'ac_minimum_price': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'ac_maximum_price': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'ac_connection_fee_on_top_of_minimum_price': new FormControl(''),
      'ac_overstay_fee': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'ac_apply_overstay_per_minute': new FormControl(''),
      'ac_overstay_interval': new FormControl('', [Validators.pattern('^[0-9]*$')]),
      'ac_maximum_charging_session_length_in_minutes': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'ac_overstay_cap': new FormControl('', [Validators.pattern('^[0-9]*$')]),
      'price_description': new FormControl('')
    });
  }

  ngOnInit() {
    this.powerTariffService.getPowerTariff(this.powerTariff).subscribe(data => {
      this.originalData = data;
      this.setDefaultValues(data);
    });
  }

  setDefaultValues(data: []) {
    setTimeout(() => {
      this.tariffForm.get('name').setValue(data['name']);
      this.tariffForm.get('dc_kwh_price').setValue(data['dc_kwh_price']);
      this.tariffForm.get('dc_connection_fee').setValue(data['dc_connection_fee']);
      this.tariffForm.get('dc_minimum_price').setValue(data['dc_minimum_price']);
      this.tariffForm.get('dc_maximum_price').setValue(data['dc_maximum_price']);
      this.tariffForm.get('dc_connection_fee_on_top_of_minimum_price').setValue(data['dc_connection_fee_on_top_of_minimum_price']);
      this.tariffForm.get('dc_overstay_fee').setValue(data['dc_overstay_fee']);
      this.tariffForm.get('dc_overstay_cap').setValue(data['dc_overstay_cap']);
      this.tariffForm.get('dc_apply_overstay_per_minute').setValue(data['dc_apply_overstay_per_minute']);
      this.tariffForm.get('dc_overstay_interval').setValue(data['dc_overstay_interval']);
      this.tariffForm.get('dc_maximum_charging_session_length_in_minutes').setValue(data['dc_maximum_charging_session_length_in_minutes']);
      this.tariffForm.get('ac_kwh_price').setValue(data['ac_kwh_price']);
      this.tariffForm.get('ac_connection_fee').setValue(data['ac_connection_fee']);
      this.tariffForm.get('ac_minimum_price').setValue(data['ac_minimum_price']);
      this.tariffForm.get('ac_maximum_price').setValue(data['ac_maximum_price']);
      this.tariffForm.get('ac_connection_fee_on_top_of_minimum_price').setValue(data['ac_connection_fee_on_top_of_minimum_price']);
      this.tariffForm.get('ac_overstay_fee').setValue(data['ac_overstay_fee']);
      this.tariffForm.get('ac_overstay_cap').setValue(data['ac_overstay_cap']);
      this.tariffForm.get('ac_apply_overstay_per_minute').setValue(data['ac_apply_overstay_per_minute']);
      this.tariffForm.get('ac_overstay_interval').setValue(data['ac_overstay_interval']);
      this.tariffForm.get('ac_maximum_charging_session_length_in_minutes').setValue(data['ac_maximum_charging_session_length_in_minutes']);
      this.tariffForm.get('price_description').setValue(data['price_description']);
    }, 0)
  }

  onSubmit(data: []) {
    return this.powerTariffService.editPowerTariff(this.powerTariff, data).subscribe(response => {
      this.refreshTariffs.emit();
      this.toastr.success('Power tariff has been updated successfully');
      this.close.emit();
    });
  }

  onCancel() {
    this.close.emit();
  }
}
