import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CookieService } from "ngx-cookie-service";
import { HeaderComponent } from "./components/header/header.component";
import { NavComponent } from "./components/nav/nav.component";
import { HomeComponent } from "./components/home/home.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { AuthService } from "./services/auth.service";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SettingService } from "./services/setting.service";
import { ButtonModule } from "primeng/button";
import { TableModule } from "primeng/table";
import { TreeTableModule } from "primeng/treetable";
import { TreeModule } from "primeng/tree";
import { TooltipModule } from "primeng/tooltip";
import { DialogModule } from "primeng/dialog";
import { InputTextModule } from "primeng/inputtext";
import { RadioButtonModule } from "primeng/radiobutton";
import { CheckboxModule } from "primeng/checkbox";
import { DropdownModule } from "primeng/dropdown";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { ToastModule } from "primeng/toast";
import { CalendarModule } from "primeng/calendar";
import { FileUploadModule } from "primeng/fileupload";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PrimengService } from "./services/primeng.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MessageService } from "primeng/api";
import { PowerTariffComponent } from "./components/power-tariff/power-tariff.component";
import { PowerTariffsListComponent } from "./components/power-tariff/power-tariffs-list/power-tariffs-list.component";
import { CreatePowerTariffComponent } from "./components/power-tariff/create-power-tariff/create-power-tariff.component";
import { ToastrModule } from "ngx-toastr";
import { EditPowerTariffComponent } from "./components/power-tariff/edit-power-tariff/edit-power-tariff.component";
import { DeletePowerTariffComponent } from "./components/power-tariff/delete-power-tariff/delete-power-tariff.component";
import { ChargeGroupsListComponent } from "./components/power-tariff/charge-groups/charge-groups-list/charge-groups-list.component";
import { CreateChargeGroupComponent } from "./components/power-tariff/charge-groups/create-charge-group/create-charge-group.component";
import { EditChargeGroupComponent } from "./components/power-tariff/charge-groups/edit-charge-group/edit-charge-group.component";
import { DeleteChargeGroupComponent } from "./components/power-tariff/charge-groups/delete-charge-group/delete-charge-group.component";
import { ChargingPointsListComponent } from "./components/power-tariff/charging-points/charging-points-list/charging-points-list.component";
import { CreateChargingPointComponent } from "./components/power-tariff/charging-points/create-charging-point/create-charging-point.component";
import { EditChargingPointComponent } from "./components/power-tariff/charging-points/edit-charging-point/edit-charging-point.component";
import { DeleteChargingPointComponent } from "./components/power-tariff/charging-points/delete-charging-point/delete-charging-point.component";
import { ScheduleTariffsListComponent } from "./components/power-tariff/schedule-tariffs/schedule-tariffs-list/schedule-tariffs-list.component";
import { CreateScheduleTariffComponent } from "./components/power-tariff/schedule-tariffs/create-schedule-tariff/create-schedule-tariff.component";
import { EditScheduleTariffComponent } from "./components/power-tariff/schedule-tariffs/edit-schedule-tariff/edit-schedule-tariff.component";
import { DeleteScheduleTariffComponent } from "./components/power-tariff/schedule-tariffs/delete-schedule-tariff/delete-schedule-tariff.component";
import { TariffsHistoryListComponent } from "./components/power-tariff/schedule-tariffs/tariffs-history-list/tariffs-history-list.component";
import { LinkedTariffsListComponent } from "./components/power-tariff/linked-tariffs/linked-tariffs-list/linked-tariffs-list.component";
import { CreateLinkedTariffComponent } from "./components/power-tariff/linked-tariffs/create-linked-tariff/create-linked-tariff.component";
import { EditLinkedTariffComponent } from "./components/power-tariff/linked-tariffs/edit-linked-tariff/edit-linked-tariff.component";
import { DeleteLinkedTariffComponent } from "./components/power-tariff/linked-tariffs/delete-linked-tariff/delete-linked-tariff.component";
import { MatChipsModule, MatFormFieldModule, MatIconModule } from "@angular/material";
import { NgxTreeSelectModule } from 'ngx-tree-select';
import { PriceScheduleComponent } from "./components/power-tariff/price-schedule/price-schedule.component";
import { CreatePriceScheduleComponent } from './components/power-tariff/price-schedule/create-price-schedule/create-price-schedule.component';
import { EditPriceScheduleComponent } from './components/power-tariff/price-schedule/edit-price-schedule/edit-price-schedule.component';
import { ClearDayComponent } from './components/power-tariff/price-schedule/clear-day/clear-day.component';
import { DeletePriceScheduleComponent } from './components/power-tariff/price-schedule/delete-price-schedule/delete-price-schedule.component';
import { CopyPriceScheduleComponent } from './components/power-tariff/price-schedule/copy-price-schedule/copy-price-schedule.component';
import { ProgressBarModule } from "primeng/progressbar";
import { ListComponent } from './list/list.component';
import { EditListComponent } from './list/edit-list/edit-list.component';
import { CreateListComponent } from './list/create-list/create-list.component';
import { DeleteListComponent } from './list/delete-list/delete-list.component';
import { MemberListComponent } from './list/member-list/member-list.component';
import { CreateMemberListComponent } from './list/create-member-list/create-member-list.component';
import { EditMemberListComponent } from './list/edit-member-list/edit-member-list.component';
import { BulkUploadMemberComponent } from './list/bulk-upload-member/bulk-upload-member.component';
import { BulkUploadChargingPointComponent } from './components/power-tariff/charging-points/bulk-upload-charging-point/bulk-upload-charging-point.component';
import { LoginComponent } from './login/login.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "./assets/i18n/");
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavComponent,
    HomeComponent,
    PowerTariffComponent,
    PowerTariffsListComponent,
    CreatePowerTariffComponent,
    EditPowerTariffComponent,
    DeletePowerTariffComponent,
    ChargeGroupsListComponent,
    CreateChargeGroupComponent,
    EditChargeGroupComponent,
    DeleteChargeGroupComponent,
    ChargingPointsListComponent,
    CreateChargingPointComponent,
    EditChargingPointComponent,
    DeleteChargingPointComponent,
    ScheduleTariffsListComponent,
    EditScheduleTariffComponent,
    DeleteScheduleTariffComponent,
    CreateScheduleTariffComponent,
    TariffsHistoryListComponent,
    LinkedTariffsListComponent,
    CreateLinkedTariffComponent,
    EditLinkedTariffComponent,
    DeleteLinkedTariffComponent,
    PriceScheduleComponent,
    CreatePriceScheduleComponent,
    EditPriceScheduleComponent,
    ClearDayComponent,
    DeletePriceScheduleComponent,
    CopyPriceScheduleComponent,
    ListComponent,
    EditListComponent,
    CreateListComponent,
    DeleteListComponent,
    MemberListComponent,
    CreateMemberListComponent,
    EditMemberListComponent,
    BulkUploadMemberComponent,
    BulkUploadChargingPointComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    ButtonModule,
    BrowserAnimationsModule,
    TableModule,
    TreeTableModule,
    TreeModule,
    DialogModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    TooltipModule,
    RadioButtonModule,
    CheckboxModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    CalendarModule,
    FileUploadModule,
    MatIconModule,
    MatChipsModule,
    MatFormFieldModule,
    ProgressBarModule,
    ToastrModule.forRoot({
      newestOnTop: true,
      timeOut: 5000,
      preventDuplicates: true,
      closeButton: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxTreeSelectModule.forRoot({
      allowFilter: true,
      maxVisibleItemCount: 5,
      allowParentSelection: true,
      filterCaseSensitive: false,
      expandMode: 'Selection',
    }),
  ],
  providers: [
    CookieService,
    AuthService,
    SettingService,
    PrimengService,
    MessageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
