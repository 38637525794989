import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ChargeGroup } from "../../../../models/chargeGroup.model";
import { ToastrService } from "ngx-toastr";
import { LinkedList } from "../../../../models/linkedList.model";
import { LinkedTariffService } from "../../../../services/customer/linkedTariff.service";
import { LinkedTariff } from "../../../../models/linkedTariff.model";

@Component({
  selector: "app-edit-linked-tariff",
  templateUrl: "./edit-linked-tariff.component.html",
  styleUrls: ["./edit-linked-tariff.component.sass"],
})
export class EditLinkedTariffComponent implements OnInit {
  @Input() linkedTariff: LinkedTariff;
  @Input() chargeGroup: ChargeGroup;
  @Output() close = new EventEmitter();
  @Output() refreshLinkedTariffs = new EventEmitter();

  linkedTariffForm: FormGroup;
  timeLinkingLists: LinkedList[];
  powerLinkingLists: LinkedList[];

  constructor(
    private linkedTariffService: LinkedTariffService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.linkedTariffForm = this.fb.group({
      tariff_type: new FormControl("", Validators.required),
      list: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.setLinkedPowerLists();
    setTimeout(() => {
      this.linkedTariffForm
        .get("tariff_type")
        .setValue(this.linkedTariff.tariffType);
      this.linkedTariffForm.get("list").setValue(
        new LinkedList({
          list_id: this.linkedTariff.listId,
          list_name: this.linkedTariff.listName,
        })
      );
    }, 0);
  }

  setLinkedTimeLists() {
    this.linkedTariffService
      .getTimeLinkingLists()
      .subscribe(
        (timeLinkingLists) => (this.timeLinkingLists = timeLinkingLists)
      );
  }

  setLinkedPowerLists() {
    this.linkedTariffService
      .getPowerLinkingLists()
      .subscribe(
        (powerLinkingLists) => (this.powerLinkingLists = powerLinkingLists)
      );
  }

  onSubmit(data: []) {
    const params = {
      charge_group_id: this.chargeGroup.id,
      list_id: data["list"].listId,
    };

    return this.linkedTariffService
      .updateLinkedTariff(this.linkedTariff, params)
      .subscribe(() => {
        this.refreshLinkedTariffs.emit();
        this.toastr.success("List Link has been created successfully");
        this.close.emit();
      });
  }

  onCancel() {
    this.close.emit();
  }
}
