export class TariffHistory {
    id: number;
    carpark_id: number;
    carpark_name: string;
    address: string;
    tariffs: [];
    tariffsNames: [];
    
    constructor(tariffHistory) {
        if (tariffHistory) {
            this.id = tariffHistory.id;
            this.carpark_id = tariffHistory.carpark_id;
            this.carpark_name = tariffHistory.carpark_name;
            this.address = tariffHistory.address;
            this.tariffs = tariffHistory.tariffs;
            this.tariffsNames = tariffHistory.tariffs ? tariffHistory.tariffs.map(one => {
                return one.tariff_name;
            }) : [];
        }
    }
}
