export class MemberList {
  id: number;
  listId: number;
  rfid: string;
  dateEntered: string;
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
  bh: boolean;
  sd: boolean;
  startTime: string;
  endTime: string;
  validFrom: string;
  validTo: string;
  notes: string;

  constructor(member) {
    if (member) {
      this.id = member.id;
      this.listId = member.list_id;
      this.rfid = member.rfid;
      this.dateEntered = member.date_entered;
      this.mon = member.mon;
      this.tue = member.tue;
      this.wed = member.wed;
      this.thu = member.thu;
      this.fri = member.fri;
      this.sat = member.sat;
      this.sun = member.sun;
      this.bh = member.bh;
      this.sd = member.sd;
      this.startTime = member.start_time;
      this.endTime = member.end_time;
      this.validFrom = member.valid_from ? member.valid_from : null;
      this.validTo = member.valid_to ? member.valid_to : null;
      this.notes = member.notes ? member.notes : null;
    }
  }
}
