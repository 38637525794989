import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../auth.service';
import { PowerTariff } from "../../models/powerTariff.model";
import swal from "sweetalert2";
import { PriceSchedule } from "../../models/priceSchedule.model";

@Injectable({
  providedIn: 'root'
})
export class PowerTariffService {
  user: User;
  token: string;
  options: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.token = this.authService.getToken();
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'TOKEN': this.token,
      })
    };

  }

  public getPowerTariffs(): Observable<Array<PowerTariff>> {
    const url = `${env.veriparkUrl}/api/power_tariffs`;
    return this.http.get<PowerTariff[]>(url, this.options).pipe(
      map(resp => {
        return resp['data'].map(e => new PowerTariff(e));
      }),
      catchError(this.handleError)
    );
  }

  public addPowerTariff(data: []): Observable<PowerTariff> {
    data['dc_connection_fee_on_top_of_minimum_price'] = data['dc_connection_fee_on_top_of_minimum_price'] ? 1 : 0;
    data['ac_connection_fee_on_top_of_minimum_price'] = data['ac_connection_fee_on_top_of_minimum_price'] ? 1 : 0;
    data['dc_apply_overstay_per_minute'] = data['dc_apply_overstay_per_minute'] ? 1 : 0;
    data['ac_apply_overstay_per_minute'] = data['ac_apply_overstay_per_minute'] ? 1 : 0;
    data['customer_id'] = 1;//this.user.customer_id;
    return this.http.post(`${env.veriparkUrl}/api/power_tariff`, {data}, this.options).pipe(
      map(resp => {
        return new PowerTariff(resp['data']);
      }),
      catchError(this.handleError)
    );
  }


  public editPowerTariff(powerTariff: PowerTariff, data: []): Observable<PowerTariff> {
    data['dc_connection_fee_on_top_of_minimum_price'] = data['dc_connection_fee_on_top_of_minimum_price'] ? 1 : 0;
    data['ac_connection_fee_on_top_of_minimum_price'] = data['ac_connection_fee_on_top_of_minimum_price'] ? 1 : 0;
    data['dc_apply_overstay_per_minute'] = data['dc_apply_overstay_per_minute'] ? 1 : 0;
    data['ac_apply_overstay_per_minute'] = data['ac_apply_overstay_per_minute'] ? 1 : 0;
    data['customer_id'] = 1;//this.user.customer_id;
    return this.http.put(`${env.veriparkUrl}/api/power_tariff/${powerTariff.id}`, {data}, this.options).pipe(
      map(resp => {
        return new PowerTariff(resp['data']);
      }),
      catchError(this.handleError)
    );
  }

  public getPowerTariff(powerTariff: PowerTariff): Observable<[]> {
    return this.http.get(`${env.veriparkUrl}/api/power_tariff/${powerTariff.id}`, this.options).pipe(
      map(resp => {
        return resp['data'];
      }),
      catchError(this.handleError)
    );
  }


  public deletePowerTariff(powerTariff: PowerTariff): Observable<[]> {
    return this.http.delete(`${env.veriparkUrl}/api/power_tariff/${powerTariff.id}`, this.options).pipe(
      map(resp => {
        return resp['data'];
      }),
      catchError(this.handleError)
    );
  }

  public getPriceSchedules(powerTariff: PowerTariff): Observable<Array<PriceSchedule>> {
    let url = `${env.veriparkUrl}/api/power_tariff/${powerTariff.id}/price_schedules`;
    return this.http.get<PriceSchedule[]>(url, this.options).pipe(
      map(resp => {
        return resp['data'].map(e => new PriceSchedule(e));
      }),
      catchError(this.handleError)
    );
  }

  public addNewPriceSchedule(params): Observable<[]> {
    let url = `${env.veriparkUrl}/api/power_tariff/${params.power_tariff_id}/price_schedule`;
    return this.http.post<PriceSchedule[]>(url, {data: params}, this.options).pipe(
      map(resp => {
        return resp['data'];
      }),
      catchError(this.handleError)
    );
  }

  public editPriceSchedule(schedule: PriceSchedule, params): Observable<[]> {
    let url = `${env.veriparkUrl}/api/power_tariff/${params.power_tariff_id}/price_schedule/${schedule.id}`;
    return this.http.put<any[]>(url, {data: params}, this.options).pipe(
      map(resp => {
        return resp['data'];
      }),
      catchError(this.handleError)
    );
  }

  public clearSchedules(powerTariff: PowerTariff, params): Observable<[]> {
    let url = `${env.veriparkUrl}/api/power_tariff/${powerTariff.id}/clear_day`;
    return this.http.put(url, {data: params}, this.options).pipe(
      map(resp => {
        return resp['data'];
      }),
      catchError(this.handleError)
    );
  }

  public deleteSchedule(powerTariff: PowerTariff, schedule: PriceSchedule): Observable<[]> {
    let url = `${env.veriparkUrl}/api/power_tariff/${powerTariff.id}/price_schedule/${schedule.id}`;
    return this.http.delete<PowerTariff[]>(url, this.options).pipe(
      map(resp => {
        return resp['data'];
      }),
      catchError(this.handleError)
    );
  }

  public copySchedulesFromDay(powerTariff: PowerTariff, params): Observable<[]> {
    let url = `${env.veriparkUrl}/api/power_tariff/${powerTariff.id}/copy_day`;
    return this.http.put<PriceSchedule[]>(url, {data: params}, this.options).pipe(
      map(resp => {
        return resp['data'];
      }),
      catchError(this.handleError)
    );
  }

  public handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      if (error.error) {
        const errors = error.error.errors;
        if (typeof errors === 'object') {
          Object.keys(errors).forEach(function (key) {
            const error = errors[key];
            if (typeof error === 'string') {
              swal.fire('Error', error, 'error').then(r => {
                console.log(r);
              });
            } else {
              if (error.message) {
                swal.fire('Error', error.message, 'error').then(r => {
                  console.log(r);
                });
              }
            }
          });
        }
      }
    }

    return throwError(errorMessage);
  }
}
