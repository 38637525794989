import {Component, Output, EventEmitter, OnInit, Input} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ChargeGroupService } from "../../../../services/customer/chargeGroup.service";
import { ChargeGroup } from "../../../../models/chargeGroup.model";
import { ChargingPointService } from "../../../../services/customer/chargingPoint.service";

@Component({
  selector: 'app-create-charging-point',
  templateUrl: './create-charging-point.component.html',
  styleUrls: ['./create-charging-point.component.sass']
})

export class CreateChargingPointComponent implements OnInit {

  @Input() chargeGroup: ChargeGroup;
  @Output() close = new EventEmitter();
  @Output() refreshChargingPoints = new EventEmitter();

  chargingPointForm: FormGroup;
  chargeGroups: ChargeGroup[];
  originalData: [];

  constructor(
    private chargeGroupService: ChargeGroupService,
    private chargingPointService: ChargingPointService,
    private fb: FormBuilder, private toastr: ToastrService
  ) {
    this.chargingPointForm = this.fb.group({
      'name': new FormControl('', Validators.required),
      'charge_group': new FormControl('', [Validators.required]),
      'ip_address': new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.chargeGroupService.getChargeGroups().subscribe(chargeGroups => this.chargeGroups = chargeGroups);
    this.chargingPointForm.get('charge_group').setValue(this.chargeGroup);
  }

  onSubmit(data: []) {
    const params = {
      charge_group_id: data['charge_group'].id,
      name: data['name'],
      ip_address: data['ip_address'] || '',
    };
    return this.chargingPointService.addChargingPoint(params).subscribe(() => {
      this.refreshChargingPoints.emit();
      this.toastr.success('Charging point has been created successfully');
      this.close.emit();
    });
  }

  onCancel() {
    this.close.emit();
  }
}
