export class ChargeGroup {
  id: number;
  name: string;
  status: 'default' | 'active' | 'scheduled';
  chargingPointCount: number;
  customerId: number;
  tariffs: [{ id: number, name: string, status: string, tariffType: 'time' | 'power' }];
  tariffsNames: [];

  constructor(chargeGroup) {
    if (chargeGroup) {
      this.id = chargeGroup.id;
      this.name = chargeGroup.name;
      this.customerId = chargeGroup.customer_id ? chargeGroup.customer_id : 1;
      this.status = chargeGroup.status ? chargeGroup.status : null;
      this.chargingPointCount = chargeGroup.charging_point_count ? chargeGroup.charging_point_count : 0;
      this.tariffs = chargeGroup.tariffs ? chargeGroup.tariffs.map(one => {
        return {
          id: one.id,
          name: one.name,
          status: one.status,
          tariffType: one.tariff_type
        };
      }) : [];
      this.tariffsNames = chargeGroup.tariffs ? chargeGroup.tariffs.map(group => {
        return group.name;
      }) : [];
    }
  }
}
