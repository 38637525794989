import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from "../services/auth.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authService : AuthService,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });

    }

// for accessing to form fields
    get fval() { return this.loginForm.controls; }

    onFormSubmit() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;

        this.authService.login(this.fval.email.value, this.fval.password.value)
          .subscribe(
            data => {
              localStorage.setItem('userToken', JSON.stringify(data));
              this.router.navigate(['/power-tariff']);
            },
            error => {
              this.toastr.error(error.error.message, 'Error');
              this.loading = false;
            });
    }
}