import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PrimengService} from "../../../../services/primeng.service";
import {ScheduleTariffService} from "../../../../services/customer/scheduleTariff.service";
import {ChargeGroup} from "../../../../models/chargeGroup.model";
import {PowerTariffHistory} from "../../../../models/powerTariffHistory.model";

@Component({
  selector: 'app-tariffs-history-list',
  templateUrl: './tariffs-history-list.component.html',
  styleUrls: ['./tariffs-history-list.component.sass']
})
export class TariffsHistoryListComponent implements OnInit {

  @Input() chargeGroup: ChargeGroup;
  @Output() close = new EventEmitter();

  tariffsHistory: PowerTariffHistory[] = [];

  constructor(private scheduleTariffService: ScheduleTariffService, private primeng: PrimengService) {
  }

  ngOnInit(): void {
    this.scheduleTariffService.getPowerTariffsHistory().subscribe(tariffsHistory => {
      this.tariffsHistory = tariffsHistory
    });
  }

  onCancel() {
    this.close.emit();
  }
}
