import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrganisationList } from "../../models/organisationList.model";
import { ListService } from "../../services/customer/list.service";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { PowerTariff } from "../../models/powerTariff.model";
import { PowerTariffService } from "../../services/customer/powerTariff.service";

@Component({
  selector: 'app-edit-list',
  templateUrl: './edit-list.component.html',
  styleUrls: ['./edit-list.component.sass'],
  providers: [DatePipe]
})
export class EditListComponent implements OnInit {

  @Input() list: OrganisationList;
  @Output() close = new EventEmitter();
  @Output() refreshLists = new EventEmitter();

  powerTariffs: PowerTariff[];
  originalData: [];
  listForm: FormGroup;

  error:any={isError:false,errorMessage:''};

  constructor(
    private listService: ListService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private powerTariffService: PowerTariffService,
    public datePipe: DatePipe
  ) {
    this.listForm = this.fb.group({
      'name': new FormControl('', Validators.required),
      'tariff': new FormControl('', [Validators.required]),
      'valid_from': new FormControl(''),
      'valid_to': new FormControl(''),
    }, {validators: this.dateLessThan('valid_from', 'valid_to')});
  }

  ngOnInit() {
    this.powerTariffService.getPowerTariffs()
      .subscribe((powerTariffs) => {
        this.powerTariffs = powerTariffs;
        this.setDefaultValues();
      });
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): {[key: string]: any} => {
      const f = group.controls[from];
      const t = group.controls[to];
      if (f.value > t.value) {
        return {
          dates: "Date from should be less than Date to"
        };
      }
      return {};
    };
  }

  setDefaultValues()
  {
    this.listService.getList(this.list).subscribe(data => {
      this.originalData = data;
      const selectedTariff = this.powerTariffs.find(one => {
        return one.id === this.originalData['tariff_table_id'];
      });
      setTimeout(() => {
        this.listForm.get('name').setValue(data['name']);
        this.listForm.get('tariff').setValue(selectedTariff);
        this.listForm.get('valid_from').setValue(data['valid_from']);
        this.listForm.get('valid_to').setValue(data['valid_to']);
      }, 0);
    });
  }

  validateDates(from: string, to: string) {
    if(to < from){
      this.error={isError:true,errorMessage:'Date To should be greater than Date From.'};
      return false;
    }
    return true;
  }

  onSubmit(data) {
    const params = {
      name: data['name'],
      customer_id: 0,
      list_type: 1,
      tariff_table_id: data['tariff'].id,
      valid_from: this.datePipe.transform(data['valid_from'], 'yyyy-MM-dd'),
      valid_to: this.datePipe.transform(data['valid_to'], 'yyyy-MM-dd'),
    };
    if (!params['valid_from']) {
      delete params.valid_from;
    }
    if (!params['valid_to']) {
      delete params.valid_to;
    }

    if(params['valid_to'] && params['valid_from'] && this.validateDates(params['valid_from'], params['valid_to'])) {
      return this.listService.editList(this.list, params).subscribe(() => {
        this.refreshLists.emit();
        this.toastr.success('List has been created successfully');
        this.close.emit();
      });
    }
  }

  onCancel() {
    this.close.emit();
  }
}
