import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Constants } from '../constants/app-constants';
import { Configuration } from '../constants/configuration';
import { Language } from '../enums/language.enum';
import { ILanguageService } from './language/language-service-interface';
import { DeLanguageService } from './language/de-language-service';
import { EnLanguageService } from './language/en-language-service';
import { NoLanguageService } from './language/no-language-service';
import { SvLanguageService } from './language/sv-language-service';

@Injectable()
export class SettingService {
    private languageServiceChangedSource = new BehaviorSubject<ILanguageService>(new EnLanguageService());

    public readonly languageServiceChanged = this.languageServiceChangedSource.asObservable();

    constructor(public translate: TranslateService) {}

    changeLanguageService(languageValue: string) {
        switch (languageValue) {
            case Language.EN: {
                this.languageServiceChangedSource.next(new EnLanguageService());
                break;
            }
            case Language.DE: {
                this.languageServiceChangedSource.next(new DeLanguageService());
                break;
            }
            case Language.SV: {
                this.languageServiceChangedSource.next(new SvLanguageService());
                break;
            }
            case Language.NO: {
                this.languageServiceChangedSource.next(new NoLanguageService());
                break;
            }
            default: this.languageServiceChangedSource.next(new EnLanguageService());
        }
    }

    initTranslateLib() {
        const supportedLangs = Constants.languages.map(l => l.value);
        this.translate.addLangs(supportedLangs);
        this.translate.setDefaultLang(Configuration.DEFAULT_LANGUAGE);

        const savedSelectedLang = localStorage ? localStorage[Constants.LANGUAGE] || null : null;
        if (savedSelectedLang && supportedLangs.find(lang => lang === savedSelectedLang)) {
            this.translate.use(savedSelectedLang);
        } else {
            const browserLang = this.translate.getBrowserLang();
            this.translate.use(supportedLangs.find(lang => lang === browserLang) ? browserLang : Configuration.DEFAULT_LANGUAGE);
        }
        this.changeLanguageService(this.translate.currentLang);
    }

    setLanguage(language: string) {
        this.translate.use(language);
        this.changeLanguageService(language);
        if (localStorage) {
            localStorage[Constants.LANGUAGE] = language;
        }
    }

    getCurrentLanguage() {
        const currentLang = Constants.languages.find(l => l.value === this.translate.currentLang);
        return currentLang ? currentLang : null;
    }

    getSupportedLanguages() {
        return Constants.languages;
    }

    getTranslatedErrorMessage(messageKey) {
        return this.translate.instant(Constants.ERRORS + `.${messageKey}`);
    }
}