import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {ChargingPoint} from "../../../../models/chargingPoint.model";
import {ChargingPointService} from "../../../../services/customer/chargingPoint.service";

@Component({
  selector: 'app-delete-charging-point',
  templateUrl: './delete-charging-point.component.html',
  styleUrls: ['./delete-charging-point.component.sass']
})
export class DeleteChargingPointComponent {

  @Input() chargingPoint: ChargingPoint;
  @Output() close = new EventEmitter();
  @Output() refreshChargingPoints = new EventEmitter();

  constructor(
    private chargingPointService: ChargingPointService,
    private toastr: ToastrService
  ) {
  }

  onConfirmDelete() {
    if (this.chargingPoint) {
      return this.chargingPointService.deleteChargingPoint(this.chargingPoint).subscribe(response => {
        this.toastr.success('Charging point has been deleted successfully');
        this.refreshChargingPoints.emit();
        this.close.emit();
      });
    }
  }

  onCancel() {
    this.close.emit();
  }
}
