import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LinkedTariff } from "../../../../models/linkedTariff.model";
import { LinkedTariffService } from "../../../../services/customer/linkedTariff.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-delete-linked-tariff",
  templateUrl: "./delete-linked-tariff.component.html",
  styleUrls: ["./delete-linked-tariff.component.sass"],
})
export class DeleteLinkedTariffComponent {
  @Input() linkedTariff: LinkedTariff;
  @Output() close = new EventEmitter();
  @Output() refreshLinkedTariffs = new EventEmitter();

  constructor(
    private linkedTariffService: LinkedTariffService,
    private toastr: ToastrService
  ) {}

  onConfirmDelete() {
    if (this.linkedTariff) {
      return this.linkedTariffService
        .deleteLinkedTariff(this.linkedTariff)
        .subscribe((response) => {
          this.toastr.success("List Link has been deleted successfully");
          this.refreshLinkedTariffs.emit();
          this.close.emit();
        });
    }
  }

  onCancel() {
    this.close.emit();
  }
}
