export class PowerTariffHistory {
  id: number;
  name: string;
  timeTariffId?: number;
  powerTariffId?: number;
  status: string;
  switchCode: string;
  dateFrom: string;
  dateTo: string;
  tariffType: 'time' | 'power';

  constructor(powerTariffHistory) {
    if (powerTariffHistory) {
      this.id = powerTariffHistory.id;
      this.name = powerTariffHistory.tariff_name;
      this.timeTariffId = powerTariffHistory.time_tariff_id ? powerTariffHistory.time_tariff_id : null;
      this.powerTariffId = powerTariffHistory.power_tariff_id ? powerTariffHistory.power_tariff_id : null;
      this.status = powerTariffHistory.status;
      this.switchCode = powerTariffHistory.switch_code;
      this.dateFrom = powerTariffHistory.start_date;
      this.dateTo = powerTariffHistory.end_date;

      if (powerTariffHistory.time_tariff_id) {
        this.tariffType = 'time';
      }

      if (powerTariffHistory.power_tariff_id) {
        this.tariffType = 'power';
      }
    }
  }
}
