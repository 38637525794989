import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { environment as env } from "src/environments/environment";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { User } from "src/app/models/user.model";
import { AuthService } from "../auth.service";
import swal from "sweetalert2";
import { LinkedTariff } from "../../models/linkedTariff.model";
import { ChargeGroup } from "../../models/chargeGroup.model";
import { ChargingPoint } from "../../models/chargingPoint.model";
import { LinkedList } from "../../models/linkedList.model";

@Injectable({
  providedIn: "root",
})
export class LinkedTariffService {
  user: User;
  token: string;
  options: any;

  constructor(public authService: AuthService, private http: HttpClient) {
    this.token = this.authService.getToken();
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'TOKEN': this.token,
      })
    };
  }

  addLinkedTariff(data: {
    charge_group_id: number;
    list_id: number;
  }): Observable<[]> {
    return this.http
      .post(`${env.veriparkUrl}/api/link_list`, { data }, this.options)
      .pipe(
        map((resp) => {
          return resp["data"];
        }),
        catchError(this.handleError)
      );
  }

  updateLinkedTariff(
    linkedTariff: LinkedTariff,
    data: { charge_group_id: number; list_id: number }
  ): Observable<[]> {
    return this.http
      .put(
        `${env.veriparkUrl}/api/link_list/${linkedTariff.id}`,
        { data },
        this.options
      )
      .pipe(
        map((resp) => {
          return resp["data"];
        }),
        catchError(this.handleError)
      );
  }

  deleteLinkedTariff(linkedTariff: LinkedTariff): Observable<[]> {
    return this.http
      .delete(
        `${env.veriparkUrl}/api/link_list/${linkedTariff.id}`,
        this.options
      )
      .pipe(
        map((resp) => {
          return resp["data"];
        }),
        catchError(this.handleError)
      );
  }

  getLinkedTariffs(chargeGroup: ChargeGroup): Observable<Array<LinkedTariff>> {
    const url = `${env.veriparkUrl}/api/charge_group/${chargeGroup.id}/linked_tariffs`;
    return this.http.get<LinkedTariff[]>(url, this.options).pipe(
      map((resp) => {
        return resp["data"].map((e) => new LinkedTariff(e));
      }),
      catchError(this.handleError)
    );
  }

  getPowerLinkingLists(): Observable<Array<LinkedList>> {
    const url = `${env.veriparkUrl}/api/charge_group/lists/power_lists`;
    return this.http.get<LinkedList[]>(url, this.options).pipe(
      map((resp) => {
        return resp["data"].map((e) => new LinkedList(e));
      }),
      catchError(this.handleError)
    );
  }

  getTimeLinkingLists(): Observable<Array<LinkedList>> {
    const url = `${env.veriparkUrl}/api/charge_group/lists/time_lists`;
    return this.http.get<LinkedList[]>(url, this.options).pipe(
      map((resp) => {
        return resp["data"].map((e) => new LinkedList(e));
      }),
      catchError(this.handleError)
    );
  }

  public handleError(error: HttpErrorResponse) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      if (error.error) {
        const errors = error.error.errors;
        if (typeof errors === "object") {
          Object.keys(errors).forEach(function (key) {
            const error = errors[key];
            if (typeof error === "string") {
              swal.fire("Error", error, "error").then((r) => {
                console.log(r);
              });
            } else {
              if (error.message) {
                swal.fire("Error", error.message, "error").then((r) => {
                  console.log(r);
                });
              }
            }
          });
        }
      }
    }

    return throwError(errorMessage);
  }
}
