import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import {PowerTariff} from "../../../models/powerTariff.model";
import {PowerTariffService} from "../../../services/customer/powerTariff.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-delete-power-tariff',
  templateUrl: './delete-power-tariff.component.html',
  styleUrls: ['./delete-power-tariff.component.sass']
})
export class DeletePowerTariffComponent {
  @Input() powerTariff: PowerTariff;
  @Output() close = new EventEmitter();
  @Output() refreshTariffs = new EventEmitter();

  constructor(public powerTariffService: PowerTariffService, private toastr: ToastrService) {
  }

  onConfirmDelete() {
    if (this.powerTariff) {
      return this.powerTariffService.deletePowerTariff(this.powerTariff).subscribe(response => {
        this.refreshTariffs.emit();
        this.toastr.success('Power tariff has been deleted successfully');
        this.close.emit();
      });
    }
  }

  onCancel() {
    this.close.emit();
  }
}
