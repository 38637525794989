export class CarPark {
    carpark_id: number;
    carpark_name: string;
    date_from: Date | null;
    date_to: Date | null;
    status: boolean;
    isScheduled: boolean;
    isActive: boolean;
    isDefault: boolean;

    constructor(carPark) {
        if (carPark) {
            this.carpark_id = carPark.carpark_id;
            this.carpark_name = carPark.carpark_name;
            this.date_from = carPark.date_from ? carPark.date_from : null;
            this.date_to = carPark.date_to ? carPark.date_to : null;
            this.status = carPark.status;
            this.isScheduled = this.checkScheduled(carPark);
            this.isActive = this.checkActive(carPark);
            this.isDefault = carPark.is_default;
        }
    }

    checkScheduled(carPark): boolean {
        if(new Date() < new Date(carPark.date_from) && new Date() < new Date(carPark.date_to)) {
            return true;
        }
        
        return false;
    }

    checkActive(carPark): boolean {
        if(new Date() > new Date(carPark.date_from) && new Date() < new Date(carPark.date_to)) {
            return true;
        }
        
        return false;
    }
}
