import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Tariff } from 'src/app/models/tariff.model';
import { TariffHistory } from 'src/app/models/tariffHistory.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../auth.service';
import { Band } from 'src/app/models/band.model';
import { BandRate } from 'src/app/models/bandRate.model';
import { CapCharge } from 'src/app/models/capCharge.model';
import { CarparkTariff } from 'src/app/models/carparkTariff.model';
import { CarPark } from 'src/app/models/carpark.model';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class TariffService {
    user: User;
    
    token: string;

    options: any;

    constructor(public authService: AuthService, private http: HttpClient) {
      this.authService.currentUser.subscribe(user => this.user = user);
      this.authService.observableToken.subscribe(token => {
        this.token = token;
        this.options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'X-AUTH-TOKEN': this.token,
            })
        };
      });
    }

    public getTariffsByCarpark(): Observable<Array<TariffHistory>> {
      var url = `${env.veriparkUrl}/api/carpark/tariffs-history`;
      return this.http.get<TariffHistory[]>(url, this.options).pipe(
        map(resp => {
          return resp['data']['carparks'].map(e => new TariffHistory(e));
        }),
        catchError(this.handleError)
      );
    }
  
    public getBandRates(tariff: Tariff, band: Band): Observable<Array<BandRate>> {
      var url = `${env.veriparkUrl}/api/tariff/${tariff.tariff_id}/band/${band.id}/get_rates`;
      return this.http.get<BandRate>(url, this.options).pipe(
        map(resp => {
          return resp['data'].map(e => new BandRate(e));
        }),
        catchError(this.handleError)
      );
    }

    public getCapCharges(tariffHistory: TariffHistory): Observable<Array<CapCharge>> {
      var url = `${env.veriparkUrl}/api/carpark/${tariffHistory.carpark_id}/cap-charge`;
      return this.http.get<CapCharge>(url, this.options).pipe(
        map(resp => {
          return resp['data'].map(e => new CapCharge(e));
        }),
        catchError(this.handleError)
      );
    }
    
    public getCustomerTariffs(): Observable<Array<Tariff>> {
      var url = `${env.veriparkUrl}/api/customer/${this.user.customer_id}/tariff`;
      return this.http.get<Tariff[]>(url, this.options).pipe(
        map(resp => {
          return resp['data'].map(e => new Tariff(e));
        }),
        catchError(this.handleError)
      );
    }
    
    public getTariffBands(tariff: Tariff): Observable<Array<Band>> {
      var url = `${env.veriparkUrl}/api/tariff/${tariff.tariff_id}/bands`;
      return this.http.get<Band[]>(url, this.options).pipe(
        map(resp => {
          return resp['data'].map(e => new Band(e));
        }),
        catchError(this.handleError)
      );
    }
    
    public updateTariffSchedule(carparkTariffId: number, params: object): Observable<[]> {
      var url = `${env.veriparkUrl}/api/schedule-tariff/${carparkTariffId}`;
      return this.http.put<CarparkTariff[]>(url, params, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }
    
    public updateCapChargeRate(capCharge: CapCharge,carparkId: number, params: object): Observable<[]> {
      var url = `${env.veriparkUrl}/api/carpark/${carparkId}/cap-charge/${capCharge.id}`;
      return this.http.put<[]>(url, params, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }
    
    public addNewCapChargeRate(carparkId: number, params: object): Observable<CapCharge> {
      var url = `${env.veriparkUrl}/api/carpark/${carparkId}/cap-charge`;
      return this.http.post<CapCharge>(url, params, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }

    public scheduleTariff(carparkId: number, params: object): Observable<[]> {
      var url = `${env.veriparkUrl}/api/carpark/${carparkId}/schedule-tariff`;
      return this.http.post<CarparkTariff[]>(url, params, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }

    public cloneTariff(tariff: Tariff, params: object): Observable<[]> {
      var url = `${env.veriparkUrl}/api/tariff/${tariff.tariff_id}/clone_tariff`;
      return this.http.post<Tariff[]>(url, {data: params}, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }

    public addNewTariff(params): Observable<[]> {
      params.customer_id = this.user.customer_id;
      
      var url = `${env.veriparkUrl}/api/tariff`;
      
      return this.http.post<Tariff[]>(url, {data: params}, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }

    public editTariff(tariff: Tariff, params): Observable<[]> {
      params.customer_id = this.user.customer_id;
      
      var url = `${env.veriparkUrl}/api/tariff/${tariff.tariff_id}`;
      return this.http.put<Tariff[]>(url, {data: params}, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }

    public editTariffBand(tariff: Tariff, band: Band, params): Observable<[]> {
      var url = `${env.veriparkUrl}/api/tariff/${tariff.tariff_id}/band/${band.id}`;
      return this.http.put<Tariff[]>(url, {data: params}, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }

    public addNewTariffBand(params): Observable<[]> {
      params.customer_id = this.user.customer_id;
      
      var url = `${env.veriparkUrl}/api/tariff/${params.tariff_id}/band`;
      return this.http.post<Band[]>(url, {data: params}, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }


    public editBandRate(tariff: Tariff, band: Band, bandRate: BandRate, params): Observable<[]> {
      var url = `${env.veriparkUrl}/api/tariff/${tariff.tariff_id}/band/${band.id}/rate/${bandRate.id}`;
      return this.http.put<BandRate[]>(url, {data: params}, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }
  
    public deleteCapChargeRate(capCharge: CapCharge, carparkId: number): Observable<[]> {
      var url = `${env.veriparkUrl}/api/carpark/${carparkId}/cap-charge/${capCharge.id}`;
      return this.http.delete<BandRate[]>(url, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }

    public deleteBandRate(tariff: Tariff, band: Band, bandRate: BandRate): Observable<[]> {
      var url = `${env.veriparkUrl}/api/tariff/${tariff.tariff_id}/band/${band.id}/rate/${bandRate.id}`;
      return this.http.delete<BandRate[]>(url, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }
    

    public addNewBandRate(tariff: Tariff, band: Band, params): Observable<[]> {
      var url = `${env.veriparkUrl}/api/tariff/${tariff.tariff_id}/band/${band.id}/rate`;
      return this.http.post<BandRate[]>(url, {data: params}, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }

    public copyBandsFromDay(tariff: Tariff, params): Observable<[]> {
      params.customer_id = this.user.customer_id;
      
      var url = `${env.veriparkUrl}/api/tariff/${tariff.tariff_id}/copy_day`;
      return this.http.put<Band[]>(url, {data: params}, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }

    public deleteSchedule(schedule: any): Observable<[]> {
      var url = `${env.veriparkUrl}/api/schedule-tariff/${schedule.id}?schedule_type=${schedule.tariff_status}`;
      return this.http.delete(url, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }

    public deleteTariff(tariff: Tariff): Observable<[]> {
      var url = `${env.veriparkUrl}/api/tariff/${tariff.tariff_id}`;
      return this.http.delete<Tariff[]>(url, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }
    
    public clearBands(tariff: Tariff, params): Observable<[]> {
      var url = `${env.veriparkUrl}/api/tariff/${tariff.tariff_id}/clear_day`;
      return this.http.put(url, {data: params}, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }

    public setDefaultTariff(carpark: CarPark, tariff: Tariff): Observable<[]> {
      var url = `${env.veriparkUrl}/api/carpark/${carpark.carpark_id}/default-tariff/${tariff.tariff_id}`;
      return this.http.put(url, {}, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }

    public deleteBand(tariff: Tariff, band: Band): Observable<[]> {
      var url = `${env.veriparkUrl}/api/tariff/${tariff.tariff_id}/band/${band.id}`;
      return this.http.delete<Tariff[]>(url, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }
    
    public getCarparkTariffHistory(carpark: CarPark): Observable<[]> {
      var url = `${env.veriparkUrl}/api/carpark/${carpark.carpark_id}/tariff-history`;
      return this.http.get(url, this.options).pipe(map(resp => {
        return resp['data'];
      }));
    }
    
    public getTariffActivityHistory(tariff: Tariff): Observable<[]> {
      var url = `${env.veriparkUrl}/api/tariff/${tariff.tariff_id}/carpark_history`;
      return this.http.get(url, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }

    public getEarlyBirdValues(carpark: CarPark): Observable<[]> {
      var url = `${env.veriparkUrl}/api/carpark/${carpark.carpark_id}/earlybird-hours`;
      return this.http.get(url, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }
    
    public setEarlyBirdValues(carpark: CarPark, params): Observable<[]> {
      var url = `${env.veriparkUrl}/api/carpark/${carpark.carpark_id}/earlybird-hours`;
      return this.http.post(url, params, this.options).pipe(
        map(resp => {
          return resp['data'];
        }),
        catchError(this.handleError)
      );
    }
    
    public handleError(error: HttpErrorResponse){
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        errorMessage = error.error.message;
      } else {
        if(error.error){
          var errors = error.error.errors;
          if(typeof errors === 'object') {
            Object.keys(errors).forEach(function (key){
                var error = errors[key];
                if(typeof error === 'string'){
                  swal.fire('Error', error, 'error');
                } else {
                  if(error.message){
                    swal.fire('Error', error.message, 'error');
                  }
                }
            });
          }
        }
      }

      return throwError(errorMessage);
    }
}