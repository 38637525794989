export class ScheduleTariff {
  id: number;
  name: string;
  switchCode: string;
  dateFrom: string;
  dateTo: string;
  status: 'default' | 'active' | 'scheduled' | 'archived';
  tariffType: 'time' | 'power';

  constructor(scheduleTariff) {
    if (scheduleTariff) {
      this.id = scheduleTariff.id;
      this.name = scheduleTariff.name;
      this.status = scheduleTariff.status;
      this.switchCode = scheduleTariff.switch_code;
      this.dateFrom = scheduleTariff.date_from;
      this.dateTo = scheduleTariff.date_to;
      this.tariffType = scheduleTariff.tariff_type;
    }
  }
}
