import { Component } from '@angular/core';
import { environment } from './../environments/environment';
import { AuthService } from './services/auth.service';
import { User } from './models/user.model';
import { SettingService } from './services/setting.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})

export class AppComponent {
  user:User;

  searchSightings: boolean = false;

  title: string = 'Tariff';

  veriparkUrl : string = environment.veriparkUrl;

  constructor(
    public setting: SettingService
  ) {
    this.setting.initTranslateLib();
  }

  ngOnInit(){
    //  this.forceSSL();
  }

  toggleSearchSightings(){
    this.searchSightings = !this.searchSightings;
  }

  forceSSL() {
    if (location.href.includes("localhost:4200") || location.href.includes("localhost:4300") || location.href.includes("veripark.local")) {
      return;
    }

    if (location.protocol != 'https:') {
      return window.location.href = location.href.replace('http://', 'https://');
    }
  }
}
