import {Component, Output, EventEmitter, OnInit, Input} from '@angular/core';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ChargeGroupService} from "../../../../services/customer/chargeGroup.service";
import {PowerTariffService} from "../../../../services/customer/powerTariff.service";
import {PowerTariff} from "../../../../models/powerTariff.model";
import {TariffService} from "../../../../services/customer/tariff.service";
import {Tariff} from "../../../../models/tariff.model";
import {User} from "../../../../models/user.model";

@Component({
  selector: 'app-create-charge-group',
  templateUrl: './create-charge-group.component.html',
  styleUrls: ['./create-charge-group.component.sass']
})

export class CreateChargeGroupComponent implements OnInit {

  @Input() user: User;
  @Output() close = new EventEmitter();
  @Output() refreshChargeGroups = new EventEmitter();

  chargeGroupForm: FormGroup;
  powerTariffs: PowerTariff[];
  timeTariffs: Tariff[];

  constructor(
    private chargeGroupService: ChargeGroupService,
    private powerTariffService: PowerTariffService,
    private tariffService: TariffService,
    private fb: FormBuilder, private toastr: ToastrService
  ) {
    this.chargeGroupForm = this.fb.group({
      'name': new FormControl('', Validators.required),
      'tariff_type': new FormControl('', [Validators.required]),
      'default_tariff': new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.powerTariffService.getPowerTariffs().subscribe(powerTariffs => this.powerTariffs = powerTariffs);
    this.tariffService.getCustomerTariffs().subscribe(timeTariffs => this.timeTariffs = timeTariffs);
  }

  onSubmit(data: []) {
    const params = {
      customer_id: data['default_tariff'].customerId,
      default_power_tariff_id: data['tariff_type'] === 'power' ? data['default_tariff'].id : 0,
      default_time_tariff_id: data['tariff_type'] === 'time' ? data['default_tariff'].tariff_id : 0,
      name: data['name']
    };
    return this.chargeGroupService.addChargeGroup(params).subscribe(() => {
      this.refreshChargeGroups.emit();
      this.toastr.success('Charge group has been created successfully');
      this.close.emit();
    });
  }

  onCancel() {
    this.close.emit();
  }
}
