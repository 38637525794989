import { CalendarConfiguration } from '../../constants/calendar-configuration';
import { ILanguageService } from './language-service-interface';

export class NoLanguageService implements ILanguageService {
    getCalendarValues(): any {
        return CalendarConfiguration.noCalendar;
    }

    getCalendarDateFormat(): string {
        return CalendarConfiguration.noDateFormat;
    }

    getDateTimeFormat(): string {
        return 'DD.MM.YYYY HH.mm';
    }

    getThousandSeparator(): string {
        return '.';
    }

    getDecimalSeparator(): string {
        return ',';
    }
}
