import {Component, Output, EventEmitter, OnInit, Input} from '@angular/core';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ChargeGroupService} from "../../../../services/customer/chargeGroup.service";
import {ChargeGroup} from "../../../../models/chargeGroup.model";
import {ScheduleTariffService} from "../../../../services/customer/scheduleTariff.service";
import {PowerTariff} from "../../../../models/powerTariff.model";
import {PowerTariffService} from "../../../../services/customer/powerTariff.service";
import {DatePipe} from '@angular/common'
import {Tariff} from "../../../../models/tariff.model";
import {TariffService} from "../../../../services/customer/tariff.service";

@Component({
  selector: 'app-create-schedule-tariff',
  templateUrl: './create-schedule-tariff.component.html',
  styleUrls: ['./create-schedule-tariff.component.sass'],
  providers: [DatePipe]
})

export class CreateScheduleTariffComponent implements OnInit {

  @Input() chargeGroup: ChargeGroup;
  @Output() close = new EventEmitter();
  @Output() refreshScheduleTariffs = new EventEmitter();

  scheduleTariffForm: FormGroup;
  powerTariffs: PowerTariff[];

  constructor(
    private chargeGroupService: ChargeGroupService,
    private tariffService: TariffService,
    private powerTariffService: PowerTariffService,
    private scheduleTariffService: ScheduleTariffService,
    private fb: FormBuilder, private toastr: ToastrService,
    public datePipe: DatePipe
  ) {
    this.scheduleTariffForm = this.fb.group({
      'tariff_type': new FormControl('', [Validators.required]),
      'default_tariff': new FormControl('', [Validators.required]),
      'switch_code': new FormControl('', []),
      'start_date': new FormControl('', [Validators.required]),
      'end_date': new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.powerTariffService.getPowerTariffs().subscribe(powerTariffs => this.powerTariffs = powerTariffs);
  }

  onSubmit(data: []) {
    const params = {
      charge_group_id: this.chargeGroup.id,
      power_tariff_id: data['tariff_type'] === 'power' ? data['default_tariff'].id : null,
      switch_code: data['switch_code'],
      start_date: this.datePipe.transform(data['start_date'], 'yyyy-MM-dd'),
      end_date: this.datePipe.transform(data['end_date'], 'yyyy-MM-dd'),
    };
    if (params['switch_code'] === "") {
      delete params.switch_code;
    }
    if (!params['power_tariff_id']) {
      delete params.power_tariff_id;
    }
    return this.scheduleTariffService.addScheduleTariff(params).subscribe(() => {
      this.refreshScheduleTariffs.emit();
      this.toastr.success('Schedule tariff has been created successfully');
      this.close.emit();
    });
  }

  onCancel() {
    this.close.emit();
  }
}
