export class LinkedTariff {
  id: number;
  listId: number;
  listName: string;
  tariffId: number;
  tariffName: string;
  tariffType: "power" | "time";

  constructor(linkedTariff) {
    if (linkedTariff) {
      this.id = linkedTariff.xref_id;
      this.listId = linkedTariff.list_id;
      this.listName = linkedTariff.list_name;
      this.tariffId = linkedTariff.tariff_id;
      this.tariffName = linkedTariff.tariff_name;
      this.tariffType = linkedTariff.tariff_type;
    }
  }
}
