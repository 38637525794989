import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment';
import { SettingService } from 'src/app/services/setting.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {

  veriparkUrl : string = environment.veriparkUrl;

  constructor(public setting: SettingService, private auth: AuthService) {
  }

  ngOnInit() {
  }
  
  logout(){
    localStorage.removeItem('userToken');
    return this.auth.redirectToLogin();
  }
}
