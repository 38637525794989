import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrganisationList } from "../../models/organisationList.model";
import { ListService } from "../../services/customer/list.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-delete-list',
  templateUrl: './delete-list.component.html',
  styleUrls: ['./delete-list.component.sass']
})
export class DeleteListComponent {
    @Input() list: OrganisationList;
    @Output() close = new EventEmitter();
    @Output() refreshLists = new EventEmitter();
  constructor(public listService: ListService, private toastr: ToastrService) { }

  onConfirmDelete() {
    if (this.list) {
      return this.listService.deleteList(this.list).subscribe(response => {
        this.refreshLists.emit();
        this.toastr.success('List has been deleted successfully');
        this.close.emit();
      });
    }
  }

  onCancel() {
    this.close.emit();
  }
}
