import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ListService } from "../../services/customer/list.service";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { OrganisationList } from "../../models/organisationList.model";

@Component({
  selector: 'app-create-member-list',
  templateUrl: './create-member-list.component.html',
  styleUrls: ['./create-member-list.component.sass'],
  providers: [DatePipe]
})
export class CreateMemberListComponent implements OnInit {

  @Input() list: OrganisationList;
  @Output() close = new EventEmitter();
  @Output() refreshMembers = new EventEmitter();

  memberForm: FormGroup;

  constructor(
    private listService: ListService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public datePipe: DatePipe
  ) {
    this.memberForm = this.fb.group({
      'rfid': new FormControl('', Validators.required),
      'mon': new FormControl(''),
      'tue': new FormControl(''),
      'wed': new FormControl(''),
      'thu': new FormControl(''),
      'fri': new FormControl(''),
      'sat': new FormControl(''),
      'sun': new FormControl(''),
      'start_time': new FormControl(''),
      'end_time': new FormControl(''),
      'valid_from': new FormControl(''),
      'valid_to': new FormControl(''),
      'notes': new FormControl(''),
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.memberForm.get('mon').setValue(1);
      this.memberForm.get('tue').setValue(1);
      this.memberForm.get('wed').setValue(1);
      this.memberForm.get('thu').setValue(1);
      this.memberForm.get('fri').setValue(1);
      this.memberForm.get('sat').setValue(1);
      this.memberForm.get('sun').setValue(1);
    });
  }

  onSubmit(data: []) {
    const params = {
      rfid: data['rfid'],
      mon: data['mon'],
      tue: data['tue'],
      wed: data['wed'],
      thu: data['thu'],
      fri: data['fri'],
      sat: data['sat'],
      sun: data['sun'],
      start_time: this.datePipe.transform(data['start_time'], 'H:m'),
      end_time: this.datePipe.transform(data['end_time'], 'H:m'),
      valid_from: this.datePipe.transform(data['valid_from'], 'yyyy-MM-dd'),
      valid_to: this.datePipe.transform(data['valid_to'], 'yyyy-MM-dd'),
      notes: data['notes']
    };

    if (!params['valid_from']) {
      delete params.valid_from;
    }
    if (!params['valid_to']) {
      delete params.valid_to;
    }
    if (!params['start_time']) {
      delete params.start_time;
    }
    if (!params['end_time']) {
      delete params.end_time;
    }
    if (!params['notes']) {
      delete params.notes;
    }
    if (!params['mon']) {
      params['mon'] = 0;
    }
    if (!params['tue']) {
      params['tue'] = 0;
    }
    if (!params['wed']) {
      params['wed'] = 0;
    }
    if (!params['thu']) {
      params['thu'] = 0;
    }
    if (!params['fri']) {
      params['fri'] = 0;
    }
    if (!params['sat']) {
      params['sat'] = 0;
    }
    if (!params['sun']) {
      params['sun'] = 0;
    }

    return this.listService.createMember(this.list, params).subscribe(() => {
      this.refreshMembers.emit();
      this.toastr.success('Member has been created successfully');
      this.close.emit();
    });
  }

  onCancel() {
    this.close.emit();
  }
}
