import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { PowerTariff } from "../../../../models/powerTariff.model";
import { PriceSchedule } from "../../../../models/priceSchedule.model";
import { AuthService } from "../../../../services/auth.service";
import { PowerTariffService } from "../../../../services/customer/powerTariff.service";

@Component({
  selector: 'app-delete-price-schedule',
  templateUrl: './delete-price-schedule.component.html',
  styleUrls: ['./delete-price-schedule.component.sass']
})
export class DeletePriceScheduleComponent implements OnInit {
  @Input() powerTariff: PowerTariff;
  @Input() priceSchedule: PriceSchedule;
  @Output() cancelDeleteSchedule = new EventEmitter();
  @Output() scheduleDeleted = new EventEmitter();

  priceScheduleForm: FormGroup;

  constructor(public powerTariffService: PowerTariffService, public auth: AuthService) { }

  ngOnInit() {
  }

  onConfirmDelete() {
    if(this.priceSchedule){
      return this.powerTariffService.deleteSchedule(this.powerTariff, this.priceSchedule).subscribe(response => {
        this.scheduleDeleted.emit();
      });
    }
  }

  onCancel(){
    this.cancelDeleteSchedule.emit();
  }
}
