export class PriceSchedule {
  id: number;
  power_tariff_id: number;
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
  bh: boolean;
  sd: boolean;
  start_time: string;
  end_time: string;
  dc_kwh_price: number;
  ac_kwh_price: number;
  dc_overstay_fee: number;
  ac_overstay_fee: number;
  dc_apply_overstay_per_minute: boolean;
  ac_apply_overstay_per_minute: boolean;
  original: string;

  constructor(priceSchedule) {
    if(priceSchedule) {
      this.id = priceSchedule.id;
      this.power_tariff_id = priceSchedule.power_tariff_id || undefined;
      this.mon = priceSchedule.mon || undefined;
      this.tue = priceSchedule.tue || undefined;
      this.wed = priceSchedule.wed || undefined;
      this.thu = priceSchedule.thu || undefined;
      this.fri = priceSchedule.fri || undefined;
      this.sat = priceSchedule.sat || undefined;
      this.sun = priceSchedule.sun || undefined;
      this.bh = priceSchedule.bh || undefined;
      this.sd = priceSchedule.sd || undefined;
      this.start_time = priceSchedule.start_time || undefined;
      this.end_time = priceSchedule.end_time || undefined;
      this.dc_kwh_price = priceSchedule.dc_kwh_price || undefined;
      this.ac_kwh_price = priceSchedule.ac_kwh_price || undefined;
      this.dc_overstay_fee = priceSchedule.dc_overstay_fee || undefined;
      this.ac_overstay_fee = priceSchedule.ac_overstay_fee || undefined;
      this.dc_apply_overstay_per_minute = priceSchedule.dc_apply_overstay_per_minute || undefined;
      this.ac_apply_overstay_per_minute = priceSchedule.ac_apply_overstay_per_minute || undefined;
      this.original = priceSchedule.original || undefined;
    }
  }
}