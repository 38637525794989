import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrganisationList } from "../../models/organisationList.model";
import { MemberList } from "../../models/memberList.model";
import { ListService } from "../../services/customer/list.service";
import { PrimengService } from "../../services/primeng.service";

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.sass']
})
export class MemberListComponent implements OnInit {

  @Input() list: OrganisationList;
  @Output() close = new EventEmitter();
  @Output() refreshLists = new EventEmitter();


  memberLists: MemberList[] = [];
  createMember: boolean;
  selectedMember: MemberList;
  editMember: boolean;
  deleteMember: boolean;
  loaded: boolean;
  bulkUpload: boolean;

  constructor(public listService: ListService, public primeng: PrimengService) { }

  ngOnInit() {
    this.setMembers()
  }

  setMembers()
  {
    return this.listService.getMembers(this.list).subscribe((Members) => {
      this.memberLists = Members;
      this.loaded = true;
    });
  }

  setMember(member: MemberList): void {
    this.selectedMember = member;
  }

  refreshMembers() {
    this.memberLists = [];
    this.setMembers();
  }

  onCancel() {
    this.close.emit();
  }
}
