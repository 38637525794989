import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ChargeGroup } from "../../../../models/chargeGroup.model";
import { environment as env } from "../../../../../environments/environment";
import { throwError } from "rxjs/internal/observable/throwError";

@Component({
  selector: 'app-bulk-upload-charging-point',
  templateUrl: './bulk-upload-charging-point.component.html',
  styleUrls: ['./bulk-upload-charging-point.component.sass']
})
export class BulkUploadChargingPointComponent implements OnInit {

  @Input() chargeGroup: ChargeGroup;
  @Output() close = new EventEmitter();
  @Output() refreshChargingPoints = new EventEmitter();

  status: "initial" | "uploading" | "success" | "fail" = "initial"; // Variable to store file status
  file: File | null = null; // Variable to store file

  constructor(private http: HttpClient) { }

  ngOnInit() {
  }

  onCancel() {
    this.close.emit();
  }

  onChange(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.status = "initial";
      this.file = file;
    }
  }

  onUpload() {
    if (this.file) {
      const formData:FormData = new FormData();
      formData.append('file', this.file);

      this.status = 'uploading';
      const options = {
        headers: new HttpHeaders({
          'enctype': 'multipart/form-data',
        })
      };

      this.http.post(`${env.veriparkUrl}/api/charge_group/${this.chargeGroup.id}/bulk_upload`, formData, options).subscribe({ next: () => {
        this.status = 'success';
        this.refreshChargingPoints.emit();
        this.close.emit();
      },
      error: (error: any) => {
        this.status = 'fail';
        return throwError (() => error);
      },
      });
    }
  }

}
