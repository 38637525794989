import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {ScheduleTariff} from "../../../../models/scheduleTariff.model";
import {ScheduleTariffService} from "../../../../services/customer/scheduleTariff.service";

@Component({
  selector: 'app-delete-schedule-tariff',
  templateUrl: './delete-schedule-tariff.component.html',
  styleUrls: ['./delete-schedule-tariff.component.sass']
})
export class DeleteScheduleTariffComponent {

  @Input() scheduleTariff: ScheduleTariff;
  @Output() close = new EventEmitter();
  @Output() refreshScheduleTariffs = new EventEmitter();

  constructor(
    private scheduleTariffService: ScheduleTariffService,
    private toastr: ToastrService
  ) {
  }

  onConfirmDelete() {
    if (this.scheduleTariff) {
      return this.scheduleTariffService.deleteScheduleTariff(this.scheduleTariff).subscribe(response => {
        this.toastr.success('Schedule tariff has been deleted successfully');
        this.refreshScheduleTariffs.emit();
        this.close.emit();
      });
    }
  }

  onCancel() {
    this.close.emit();
  }
}
