import { CalendarConfiguration } from '../../constants/calendar-configuration';
import { ILanguageService } from './language-service-interface';

export class EnLanguageService implements ILanguageService {
    getCalendarValues(): any {
        return CalendarConfiguration.enCalendar;
    }

    getCalendarDateFormat(): string {
        return CalendarConfiguration.enDateFormat;
    }

    getDateTimeFormat(): string {
        return 'DD/MM/YYYY HH:mm';
    }

    getThousandSeparator(): string {
        return ',';
    }

    getDecimalSeparator(): string {
        return '.';
    }
}
