import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PrimengService} from "../../../../services/primeng.service";
import {ScheduleTariff} from "../../../../models/scheduleTariff.model";
import {ScheduleTariffService} from "../../../../services/customer/scheduleTariff.service";
import {ChargeGroup} from "../../../../models/chargeGroup.model";

@Component({
  selector: 'app-schedule-tariffs-list',
  templateUrl: './schedule-tariffs-list.component.html',
  styleUrls: ['./schedule-tariffs-list.component.sass']
})
export class ScheduleTariffsListComponent implements OnInit {

  @Input() chargeGroup: ChargeGroup;
  @Output() close = new EventEmitter();
  @Output() refreshChargeGroups = new EventEmitter();

  scheduleTariffs: ScheduleTariff[] = [];
  selectedScheduleTariff: ScheduleTariff;
  createScheduleTariff: boolean;
  editScheduleTariff: boolean;
  deleteScheduleTariff: boolean;

  constructor(private scheduleTariffService: ScheduleTariffService, private primeng: PrimengService) {
  }

  ngOnInit(): void {
    this.setScheduleTariffs();
  }

  setScheduleTariff(scheduleTariff: ScheduleTariff): void {
    this.selectedScheduleTariff = scheduleTariff;
  }

  setScheduleTariffs(): void {
    this.scheduleTariffService.getScheduleTariffs(this.chargeGroup).subscribe(scheduleTariffs => {
      this.scheduleTariffs = scheduleTariffs
    });
  }

  refreshScheduleTariffs(): void {
    this.scheduleTariffs = [];
    this.setScheduleTariffs();
    this.refreshChargeGroups.emit();
  }

  onCancel() {
    this.close.emit();
  }
}
