import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { AuthService } from '../auth.service';
import swal from "sweetalert2";
import { ScheduleTariff } from "../../models/scheduleTariff.model";
import { PowerTariffHistory } from "../../models/powerTariffHistory.model";
import { ChargeGroup } from "../../models/chargeGroup.model";

@Injectable({
  providedIn: 'root'
})
export class ScheduleTariffService {
  user: User;
  token: string;
  options: any;

  constructor(public authService: AuthService, private http: HttpClient) {
    this.token = this.authService.getToken();
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'TOKEN': this.token,
      })
    };
  }

  public addScheduleTariff(data: {
    charge_group_id?: number,
    power_tariff_id?: number,
    switch_code?: string,
    start_date: string,
    end_date: string,
  }): Observable<ScheduleTariff> {
    return this.http.post(`${env.veriparkUrl}/api/power_schedule`, {data}, this.options).pipe(
      map(resp => {
        return new ScheduleTariff(resp['data']);
      }),
      catchError(this.handleError)
    );
  }

  public editScheduleTariff(scheduleTariff: ScheduleTariff, data: {
    charge_group_id: number,
    power_tariff_id: number,
    switch_code?: string,
    start_date: string,
    end_date: string,
  }): Observable<ScheduleTariff> {
    return this.http.put(`${env.veriparkUrl}/api/power_schedule/${scheduleTariff.id}`, {data}, this.options).pipe(
      map(resp => {
        return new ScheduleTariff(resp['data']);
      }),
      catchError(this.handleError)
    );
  }


  public getScheduleTariff(scheduleTariff: ScheduleTariff): Observable<[]> {
    return this.http.get(`${env.veriparkUrl}/api/power_schedule/${scheduleTariff.id}`, this.options).pipe(
      map(resp => {
        return resp['data'];
      }),
      catchError(this.handleError)
    );
  }

  public deleteScheduleTariff(scheduleTariff: ScheduleTariff): Observable<[]> {
    return this.http.delete(`${env.veriparkUrl}/api/power_schedule/${scheduleTariff.id}`, this.options).pipe(
      map(resp => {
        return resp['data'];
      }),
      catchError(this.handleError)
    );
  }

  getScheduleTariffs(chargeGroup: ChargeGroup): Observable<Array<ScheduleTariff>> {
    const url = `${env.veriparkUrl}/api/power_schedules/${chargeGroup.id}`;
    return this.http.get<ScheduleTariff[]>(url, this.options).pipe(
      map(resp => {
        return resp['data'].map(e => new ScheduleTariff(e));
      }),
      catchError(this.handleError)
    );
  }

  public getPowerTariffsHistory(): Observable<Array<PowerTariffHistory>> {
    const url = `${env.veriparkUrl}/api/power_tariffs/history`;
    return this.http.get<PowerTariffHistory[]>(url, this.options).pipe(
      map(resp => {
        return resp['data'].map(e => new PowerTariffHistory(e));
      }),
      catchError(this.handleError)
    );
  }

  public handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      if (error.error) {
        const errors = error.error.errors;
        if (typeof errors === 'object') {
          Object.keys(errors).forEach(function (key) {
            const error = errors[key];
            if (typeof error === 'string') {
              swal.fire('Error', error, 'error').then(r => {
                console.log(r);
              });
            } else {
              if (error.message) {
                swal.fire('Error', error.message, 'error').then(r => {
                  console.log(r);
                });
              }
            }
          });
        }
      }
    }

    return throwError(errorMessage);
  }
}
