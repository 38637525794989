import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PowerTariff } from "../../../models/powerTariff.model";
import { User } from "../../../models/user.model";
import { PrimengService } from "../../../services/primeng.service";
import { PowerTariffService } from "../../../services/customer/powerTariff.service";
import { AuthService } from "../../../services/auth.service";
import { MessageService } from "primeng/api";
import { Constants } from "../../../constants/app-constants";
import { PriceSchedule } from "../../../models/priceSchedule.model";

@Component({
  selector: 'app-price-schedule',
  templateUrl: './price-schedule.component.html',
  styleUrls: ['./price-schedule.component.sass']
})
export class PriceScheduleComponent implements OnInit {

  @Input() powerTariff: PowerTariff;

  @Output() hidePriceSchedules = new EventEmitter();

  //user: User;

  days: any[];

  priceSchedules: any[];

  selectedDay: object = null;

  copyBandsToDay: string = null;

  clearSchedules: any[];

  addNewPriceSchedule: object = null;

  editPriceSchedule: object = null;

  constructor(
    public primeng: PrimengService,
    public powerTariffService: PowerTariffService,
    public auth: AuthService,
    public messageService: MessageService
  ) {
    this.days = Constants.days;
  }

  ngOnInit() {
    this.getBands();
  }

  getBands() {
    let _this = this;
    return this.powerTariffService.getPriceSchedules(this.powerTariff).subscribe( (priceSchedules) => {
      _this.priceSchedules = priceSchedules;
      console.log(this.priceSchedules);

      // add keywords to search in bands list
      _this.days.forEach(day => {
        day.keywords = [];
        _this.filterBandsByKey(day.key).forEach(priceSchedule => {
          if(priceSchedule.id){
            day.keywords.push(priceSchedule.start_time);
            day.keywords.push(priceSchedule.end_time);
          }
        });
      })
    });
  }

  checkOriginalDay(priceSchedules: PriceSchedule[],dayKey: string){
    if(priceSchedules[0].original === dayKey){
      return false;
    }
    if(priceSchedules[0].original === undefined){
      return false;
    }
    let day = this.days.find(one => {
      return one.key == priceSchedules[0].original
    });
    if(day){
      return day.value;
    }
    return priceSchedules[0].original;
  }

  calculateBandWidth(priceSchedule: PriceSchedule) {
    return (this.calculateTimePercentage(priceSchedule.end_time) - this.calculateTimePercentage(priceSchedule.start_time)) - 1;
  }

  calculateTimePercentage(time: string): number {
    let parts = time.split(':');
    let response: number = 0;
    if(parts.length == 3){
      let secs = parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2]);
      return Math.round(secs / 864);
    }
    return response;
  }

  filterBandsByKey(key: string) {
    let priceSchedules = (!this.priceSchedules) ? [] : this.priceSchedules.filter(function (priceSchedule){
      return priceSchedule[key] == true;
    });

    return this.sortBandsByTime(priceSchedules);
  }

  sortBandsByTime(priceSchedules: PriceSchedule[]) {
    return priceSchedules.sort(function (a, b) {
      return new Date('1970/01/01 ' + a.start_time).getTime() - new Date('1970/01/01 ' + b.start_time).getTime();
    });
  }

  hasActiveBands(priceSchedules: PriceSchedule[]){
    return priceSchedules.filter(function (priceSchedule){
      return priceSchedule.id !== null;
    }).length > 0;
  }

  onCancel() {
    this.hidePriceSchedules.emit();
  }

  schedulesCleared(){
    this.selectedDay = null;
    this.clearSchedules = null;
    this.refreshBands();
    this.messageService.add({severity:'success', summary:'Price Schedule', detail:'Price Schedule day has been cleared successfully'});
  }

  newPriceScheduleAdded(){
    this.addNewPriceSchedule = null;
    this.refreshBands();
    this.messageService.add({severity:'success', summary:'Price Schedule', detail:'Price Schedule has been added successfully'});
  }

  priceScheduleUpdated() {
    this.editPriceSchedule = null;
      this.refreshBands();
      this.messageService.add({severity:'success', summary:'Price Schedule', detail:'Price Schedule has been updated successfully'});
  }

  refreshBands(){
    this.priceSchedules = [];
    this.getBands();
  }

  scheduleCopied(){
    this.copyBandsToDay = null;
    this.getBands();
  }

}
