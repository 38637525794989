import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {ChargeGroup} from "../../../../models/chargeGroup.model";
import {ChargeGroupService} from "../../../../services/customer/chargeGroup.service";

@Component({
  selector: 'app-delete-charge-group',
  templateUrl: './delete-charge-group.component.html',
  styleUrls: ['./delete-charge-group.component.sass']
})
export class DeleteChargeGroupComponent {

  @Input() chargeGroup: ChargeGroup;
  @Output() close = new EventEmitter();
  @Output() refreshChargeGroups = new EventEmitter();

  constructor(
    private chargeGroupService: ChargeGroupService,
    private toastr: ToastrService
  ) {
  }

  onConfirmDelete() {
    if (this.chargeGroup) {
      return this.chargeGroupService.deleteChargeGroup(this.chargeGroup).subscribe(response => {
        this.toastr.success('Charge group has been deleted successfully');
        this.refreshChargeGroups.emit();
        this.close.emit();
      });
    }
  }

  onCancel() {
    this.close.emit();
  }
}
