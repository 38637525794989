import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PowerTariff } from "../../../../models/powerTariff.model";
import { PriceSchedule } from "../../../../models/priceSchedule.model";
import { User } from "../../../../models/user.model";
import { FormGroup } from "@angular/forms";
import { AuthService } from "../../../../services/auth.service";
import { PowerTariffService } from "../../../../services/customer/powerTariff.service";

@Component({
  selector: 'app-clear-day',
  templateUrl: './clear-day.component.html',
  styleUrls: ['./clear-day.component.sass']
})
export class ClearDayComponent implements OnInit {
  @Input() powerTariff: PowerTariff;
  @Input() priceSchedules: PriceSchedule[];
  @Input() day;
  @Output() cancelClearSchedules = new EventEmitter();
  @Output() schedulesCleared = new EventEmitter();

  priceScheduleForm: FormGroup;
  user: User;

  constructor(public powerTariffSerivce: PowerTariffService, public auth: AuthService) { }

  ngOnInit() {
    this.auth.currentUser.subscribe(user => {
      this.user = user;
    })
  }

  onConfirmDelete() {
    return this.powerTariffSerivce.clearSchedules(this.powerTariff, {day_of_week: this.day.key}).subscribe(response => {
      this.schedulesCleared.emit();
    });
  }

  onCancel(){
    this.cancelClearSchedules.emit();
  }

}
