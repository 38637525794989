export class BandRate {
    id: number;
    interval_order: number;
    length: number;
    cost: number;
    rate_type: string;
    constructor(rate) {
        if (rate) {
            this.id = rate.id;
            this.interval_order = rate.interval_order;
            this.rate_type = rate.rate_type;
            this.length = rate.length ? rate.length : 0;
            this.cost = rate.cost ? rate.cost : 0;
        }
    }
}
