import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ChargeGroup } from "../../../../models/chargeGroup.model";
import { ChargeGroupService } from "../../../../services/customer/chargeGroup.service";
import { ChargingPointService } from "../../../../services/customer/chargingPoint.service";
import { ToastrService } from "ngx-toastr";
import { ChargingPoint } from "../../../../models/chargingPoint.model";

@Component({
  selector: 'app-edit-charging-point',
  templateUrl: './edit-charging-point.component.html',
  styleUrls: ['./edit-charging-point.component.sass']
})
export class EditChargingPointComponent implements OnInit {

  @Input() chargingPoint: ChargingPoint;
  @Output() close = new EventEmitter();
  @Output() refreshChargingPoints = new EventEmitter();

  chargingPointForm: FormGroup;
  chargeGroups: ChargeGroup[];
  originalData: [];

  constructor(
    private chargeGroupService: ChargeGroupService,
    private chargingPointService: ChargingPointService,
    private fb: FormBuilder, private toastr: ToastrService
  ) {
    this.chargingPointForm = this.fb.group({
      'name': new FormControl('', Validators.required),
      'charge_group': new FormControl('', [Validators.required]),
      'ip_address': new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.chargeGroupService.getChargeGroups().subscribe(chargeGroups => {
      this.chargeGroups = chargeGroups;
      this.setOriginalData();
    });
  }

  setOriginalData() {
    this.chargingPointService.getChargingPoint(this.chargingPoint).subscribe(data => {
      this.originalData = data;
      const selectedChargeGroup = this.chargeGroups.find(one => {
        return one.id === this.originalData['charge_group_id'];
      });

      setTimeout(() => {
        this.chargingPointForm.get('name').setValue(data['name']);
        this.chargingPointForm.get('charge_group').setValue(selectedChargeGroup);
        this.chargingPointForm.get('ip_address').setValue(data['ip_address']);
      }, 0)
    });
  }

  onSubmit(data: []) {
    const params = {
      charge_group_id: data['charge_group'].id,
      name: data['name'],
      ip_address: data['ip_address']
    };

    return this.chargingPointService.editChargingPoint(this.chargingPoint, params).subscribe(() => {
      this.refreshChargingPoints.emit();
      this.toastr.success('Charging point has been updated successfully');
      this.close.emit();
    });
  }

  onCancel() {
    this.close.emit();
  }
}
