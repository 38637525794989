import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { User } from "../../../../models/user.model";
import { PrimengService } from "../../../../services/primeng.service";
import { ChargeGroup } from "../../../../models/chargeGroup.model";
import { ChargeGroupService } from "../../../../services/customer/chargeGroup.service";

@Component({
  selector: "app-charge-groups-list",
  templateUrl: "./charge-groups-list.component.html",
  styleUrls: ["./charge-groups-list.component.sass"],
})
export class ChargeGroupsListComponent implements OnInit {
  @Output() viewPowerTariff = new EventEmitter();
  @Input() user: User;

  chargeGroups: ChargeGroup[] = [];
  selectedChargeGroup: ChargeGroup;
  createChargeGroup: boolean;
  editChargeGroup: boolean;
  deleteChargeGroup: boolean;
  viewChargingPoints: boolean;
  viewScheduleTariffs: boolean;
  viewTariffHistory: boolean;
  viewLinkedTariffs: boolean;
  loaded: boolean;

  constructor(
    public primeng: PrimengService,
    public chargeGroupService: ChargeGroupService
  ) {}

  ngOnInit(): void {
    this.setChargeGroups();
  }

  getStatusKey(status: "default" | "active" | "scheduled") {
    if (status === "default") {
      return 1;
    }

    if (status === "active") {
      return 2;
    }

    if (status === "scheduled") {
      return 3;
    }
  }

  sortByStatus(
    tariffs: {
      id: number;
      name: string;
      status: "default" | "active" | "scheduled";
      tariffType: "time" | "power";
    }[]
  ) {
    const self = this;
    return tariffs.sort(function (a, b) {
      return self.getStatusKey(a.status) - self.getStatusKey(b.status);
    });
  }

  selectChargeGroup(chargeGroup: ChargeGroup): void {
    this.selectedChargeGroup = chargeGroup;
  }

  setChargeGroups() {
    return this.chargeGroupService
      .getChargeGroups()
      .subscribe((chargeGroups) => {
        this.chargeGroups = chargeGroups;
        this.loaded = true;
      });
  }

  refreshChargeGroups() {
    this.chargeGroups = [];
    this.setChargeGroups();
  }
}
