import { Component, OnInit } from '@angular/core';
import { OrganisationList } from "../models/organisationList.model";
import { PrimengService } from "../services/primeng.service";
import { ListService } from "../services/customer/list.service";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})
export class ListComponent implements OnInit {

  Lists: OrganisationList[] = [];
  createList: boolean;
  selectedList: OrganisationList;
  editList: boolean;
  deleteList: boolean;
  memberList: boolean;
  loaded: boolean;

  constructor(
    public primeng: PrimengService, public listService: ListService
  ) {}

  ngOnInit() {
    this.setLists();
  }

  selectList(List: OrganisationList): void {
    this.selectedList = List;
  }

  setLists() {
    return this.listService
      .getLists()
      .subscribe((Lists) => {
        this.Lists = Lists;
        this.loaded = true;
      });
  }

  refreshLists() {
    this.Lists = [];
    this.setLists();
  }
}
