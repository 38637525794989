import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from "../../../../models/user.model";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { PowerTariff } from "../../../../models/powerTariff.model";
import { PriceSchedule } from "../../../../models/priceSchedule.model";
import { PrimengService } from "../../../../services/primeng.service";
import { AuthService } from "../../../../services/auth.service";
import { PowerTariffService } from "../../../../services/customer/powerTariff.service";
import { Constants } from "../../../../constants/app-constants";

@Component({
  selector: 'app-copy-price-schedule',
  templateUrl: './copy-price-schedule.component.html',
  styleUrls: ['./copy-price-schedule.component.sass']
})
export class CopyPriceScheduleComponent implements OnInit {

  @Input() powerTariff: PowerTariff;
  @Input() priceSchedules: PriceSchedule[];
  @Input() day;

  @Output() cancelCopySchedules = new EventEmitter();
  @Output() scheduleCopied = new EventEmitter();

  user: User;
  copySchedulesForm: FormGroup;
  days: any[];
  availableDays: any[];

  constructor(
    public primeng: PrimengService,
    public powerTariffService: PowerTariffService,
    public auth: AuthService,
    private fb: FormBuilder,
  ) {
    this.days = Constants.days;
    this.copySchedulesForm = this.fb.group({
      'day': new FormControl(null, Validators.required)
    });
  }

  ngOnInit() {
    this.availableDays = this.days.filter(day => {
      return day.keywords.length > 0
    });

    this.auth.currentUser.subscribe(user => {
      this.user = user;
    });
  }

  onCancel() {
    this.cancelCopySchedules.emit();
  }

  onSubmit(data) {
    let _this = this;
    let params = {
      'copy_from': data.day.key,
      'copy_to':this.day.key
    };

    return this.powerTariffService.copySchedulesFromDay( this.powerTariff, params).subscribe(response => {
      this.scheduleCopied.emit();
    });
  }

}
