import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ChargeGroup } from "../../../../models/chargeGroup.model";
import { LinkedTariffService } from "../../../../services/customer/linkedTariff.service";
import { ToastrService } from "ngx-toastr";
import { LinkedList } from "../../../../models/linkedList.model";

@Component({
  selector: "app-create-linked-tariff",
  templateUrl: "./create-linked-tariff.component.html",
  styleUrls: ["./create-linked-tariff.component.sass"],
})
export class CreateLinkedTariffComponent implements OnInit {
  @Input() chargeGroup: ChargeGroup;
  @Output() close = new EventEmitter();
  @Output() refreshLinkedTariffs = new EventEmitter();

  linkedTariffForm: FormGroup;
  timeLinkingLists: LinkedList[];
  powerLinkingLists: LinkedList[];

  constructor(
    private linkedTariffService: LinkedTariffService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.linkedTariffForm = this.fb.group({
      tariff_type: new FormControl("", Validators.required),
      list: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.linkedTariffForm.get("tariff_type").setValue("power");
    }, 0);
    this.setLinkedPowerLists();
  }

  setLinkedTimeLists() {
    this.linkedTariffService
      .getTimeLinkingLists()
      .subscribe(
        (timeLinkingLists) => (this.timeLinkingLists = timeLinkingLists)
      );
  }

  setLinkedPowerLists() {
    this.linkedTariffService
      .getPowerLinkingLists()
      .subscribe(
        (powerLinkingLists) => (this.powerLinkingLists = powerLinkingLists)
      );
  }

  onSubmit(data: []) {
    const params = {
      charge_group_id: this.chargeGroup.id,
      list_id: data["list"].listId,
    };

    return this.linkedTariffService.addLinkedTariff(params).subscribe(() => {
      this.refreshLinkedTariffs.emit();
      this.toastr.success(" List Link has been created successfully");
      this.close.emit();
    });
  }

  onCancel() {
    this.close.emit();
  }
}
