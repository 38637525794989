import { Injectable, isDevMode } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';
import { ActivatedRoute, Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

    private user = new BehaviorSubject<User>(null);
    currentUser = this.user.asObservable();

    private token = new BehaviorSubject<string>("");
    observableToken = this.token.asObservable();
    currentToken: string;

    authUrl: string = `${env.veriparkUrl}/api/whoami`;

    constructor(
      private http: HttpClient,
      private cookieService: CookieService,
      private route: ActivatedRoute,
      private router: Router
    )
    {}

    public login(email: string, password: string){
        let data = {
            'username': email,
            'password': password
            };
        return this.http.post(`${env.veriparkUrl}/api/login`, {data}).pipe(map(resp => {
            return resp['data'];
        }));
    }

    public getToken(){
        let userToken = localStorage.getItem('userToken') ? JSON.parse(localStorage.getItem('userToken')) : null;
        if(userToken) {
            return userToken['token'];
        }
        return this.redirectToLogin()
    }

    public setAuthUser(){
      this.authenticate().subscribe(user => {
        if(!user.id){
          this.redirectToLogin();
        } else {
          this.user.next(user);
        }
      }, () => {
        this.redirectToLogin();
      })
    }

    private authenticate (): Observable<User> {
      const options = { 
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': this.currentToken,
            'Access-Control-Allow-Origin': '*'
          })
      };
      
      return this.http.get<User>(this.authUrl, options).pipe(map(resp => {
        return new User(resp);
      }));
    }

    private prepareToken (): void {
      let sessionValue = this.cookieService.get('session');
      if(isDevMode){
        if(!sessionValue){
          sessionValue = env.session ? env.session : "";
        }
      }
      
      this.token.next(sessionValue);
    }

    public redirectToLogin() {
        return this.router.navigate(['/login']);
    }


    public logoutRequest(): Observable<any> {
      const options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
      };
      
      var url = `${env.veriparkUrl}/services/users/v1/service.php?action=logout`;
      return this.http.get(url, options).pipe(
        map(resp => {
          return resp;
        })
      );
    }
}
