import {Component, OnInit} from '@angular/core';
import {User} from 'src/app/models/user.model';
import {AuthService} from 'src/app/services/auth.service';

@Component({
  selector: 'app-power-tariff',
  templateUrl: './power-tariff.component.html',
  styleUrls: ['./power-tariff.component.sass']
})
export class PowerTariffComponent implements OnInit {
  //user: User;
  view: 'power-tariff' | 'charge-groups';

  constructor(
  ) {
  }

  ngOnInit() {
      this.view = "power-tariff";
  }

  viewPowerTariff() {
    this.view = "power-tariff";
  }

  viewChargeGroups() {
    this.view = "charge-groups";
  }
}
