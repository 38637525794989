import {Injectable} from "@angular/core";
import {environment as env} from "../../../environments/environment";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {AuthService} from "../auth.service";
import {User} from "../../models/user.model";
import {OrganisationList} from "../../models/organisationList.model";
import {Observable, throwError} from "rxjs/index";
import swal from "sweetalert2";
import {ScheduleTariff} from "../../models/scheduleTariff.model";
import {PowerTariff} from "../../models/powerTariff.model";
import {ChargingPoint} from "../../models/chargingPoint.model";
import {MemberList} from "../../models/memberList.model";

@Injectable({
    providedIn: 'root'
})

export class ListService {

  user: User;
  token: string;
  options: any;

  constructor(public authService: AuthService, private http: HttpClient) {
    this.token = this.authService.getToken();
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'TOKEN': this.token,
      })
    };
  }

  public getLists(): Observable<Array<OrganisationList>> {
    const url = `${env.veriparkUrl}/api/get_lists`;
    return this.http.get<OrganisationList[]>(url, this.options).pipe(
      map(resp => {
        return resp['data'].map(e => new OrganisationList(e));
      }),
      catchError(this.handleError)
    );
  }

  public getList(list: OrganisationList): Observable<[]> {
    return this.http.get(`${env.veriparkUrl}/api/list/${list.id}`, this.options).pipe(
      map(resp => {
        return resp['data'];
      }),
      catchError(this.handleError)
    );
  }

  public createList(data: {
    name: string,
    customer_id: number,
    list_type: number,
    tariff_table_id: number,
    valid_from?: string,
    valid_to?: string,
  }): Observable <OrganisationList>
  {
    return this.http.post(`${env.veriparkUrl}/api/list`, {data}, this.options).pipe(
      map(resp => {
        return new OrganisationList(resp['data']);
      }),
        catchError(this.handleError)
    );
  }

  public editList(list: OrganisationList, data): Observable<OrganisationList> {
    return this.http.put(`${env.veriparkUrl}/api/list/${list.id}`, {data}, this.options).pipe(
      map(resp => {
        return new OrganisationList(resp['data']);
      }),
      catchError(this.handleError)
    );
  }

  public deleteList(list: OrganisationList): Observable<[]> {
    return this.http.delete(`${env.veriparkUrl}/api/list/${list.id}`, this.options).pipe(
      map(resp => {
        return resp['data'];
      }),
      catchError(this.handleError)
    );
  }

  public getMembers(list: OrganisationList): Observable<Array<MemberList>> {
    return this.http.get<MemberList[]>(`${env.veriparkUrl}/api/list/${list.id}/members`, this.options).pipe(
      map(resp => {
          return resp['data'].map(e => new MemberList(e));
      }),
      catchError(this.handleError)
    );
  }

  public getMember(member: MemberList): Observable<[]> {
    return this.http.get(`${env.veriparkUrl}/api/member/${member.id}`, this.options).pipe(
      map(resp => {
        return resp['data'];
      }),
      catchError(this.handleError)
    );
  }

  public createMember(list: OrganisationList, data): Observable <MemberList>
  {
    data['list_id'] = list.id;
    return this.http.post(`${env.veriparkUrl}/api/member`, {data}, this.options).pipe(
      map(resp => {
        return new MemberList(resp['data']);
      }),
      catchError(this.handleError)
    );
  }

  public editMember(member: MemberList, data): Observable<MemberList> {
    return this.http.put(`${env.veriparkUrl}/api/member/${member.id}`, {data}, this.options).pipe(
      map(resp => {
        return new MemberList(resp['data']);
      }),
      catchError(this.handleError)
    );
  }

  public bulkUploadMembers(list: OrganisationList, data): Observable<[]> {
    this.options = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
      })
    };
    return this.http.post(`${env.veriparkUrl}/api/list/${list.id}/bulk_upload`, {data}, this.options).pipe(
      map(resp => {
        return resp['data'];
      }),
      catchError(this.handleError)
    );
  }

  public handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      if (error.error) {
        const errors = error.error.errors;
        if (typeof errors === 'object') {
          Object.keys(errors).forEach(function (key) {
            const error = errors[key];
            if (typeof error === 'string') {
              swal.fire('Error', error, 'error').then(r => {
                console.log(r);
              });
            } else {
              if (error.message) {
                swal.fire('Error', error.message, 'error').then(r => {
                  console.log(r);
                });
              }
            }
          });
        }
      }
    }
    return throwError(errorMessage);
  }
}