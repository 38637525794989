import { CalendarConfiguration } from '../../constants/calendar-configuration';
import { ILanguageService } from './language-service-interface';

export class SvLanguageService implements ILanguageService {
    getCalendarValues(): any {
        return CalendarConfiguration.svCalendar;
    }

    getCalendarDateFormat(): string {
        return CalendarConfiguration.svDateFormat;
    }

    getDateTimeFormat(): string {
        return 'YYYY-MM-DD HH.mm';
    }

    getThousandSeparator(): string {
        return '.';
    }

    getDecimalSeparator(): string {
        return ',';
    }
}
