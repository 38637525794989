import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PowerTariffComponent } from "./components/power-tariff/power-tariff.component";
import { ListComponent } from "./list/list.component";
import { LoginComponent } from "./login/login.component";

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'power-tariff', component: PowerTariffComponent },
  { path: 'lists', component: ListComponent },
  { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
