export class OrganisationList {
    id: number;
    name: string;
    memberCount: boolean;
    tariffName: string;

    constructor(list) {
      if (list) {
        this.id = list.id;
        this.name = list.name;
        this.memberCount = list.member_count ? list.member_count : 0;
        this.tariffName = list.tariff_name ? list.tariff_name : null;
      }
    }
}