import { CalendarConfiguration } from '../../constants/calendar-configuration';
import { ILanguageService } from './language-service-interface';

export class DeLanguageService implements ILanguageService {
    getCalendarValues(): any {
        return CalendarConfiguration.deCalendar;
    }

    getCalendarDateFormat(): string {
        return CalendarConfiguration.deDateFormat;
    }

    getDateTimeFormat(): string {
        return 'DD.MM.YYYY HH:mm';
    }

    getThousandSeparator(): string {
        return '.';
    }

    getDecimalSeparator(): string {
        return ',';
    }
}
