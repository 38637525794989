export class Band {
    id: number;
    tariff_table_id: number;
    name: string;
    mon: boolean;
    tue: boolean;
    wed: boolean;
    thu: boolean;
    fri: boolean;
    sat: boolean;
    sun: boolean;
    bh: boolean;
    sd: boolean;
    start_time: string;
    end_time: string;
    final_period: number;
    final_cost: number;
    fixed_cost: number;
    max_cost: number;
    max_stay: number;
    free_time: number;
    type: string;
    status: string;
    original: string;

    constructor(band) {
        if (band) {
            this.id = band.id;
            this.tariff_table_id = band.tariff_table_id;
            this.name = band.name;
            this.mon = band.mon;
            this.tue = band.tue;
            this.wed = band.wed;
            this.thu = band.thu;
            this.fri = band.fri;
            this.sat = band.sat;
            this.sun = band.sun;
            this.bh = band.bh;
            this.sd = band.sd;
            this.start_time = band.start_time;
            this.end_time = band.end_time;
            this.final_period = band.final_period;
            this.final_cost = band.final_cost;
            this.fixed_cost = band.fixed_cost;
            this.max_cost = band.max_cost;
            this.max_stay = band.max_stay;
            this.free_time = band.free_time;
            this.type = band.type ? band.type : 'free';
            this.status = this.setStatus(band);
            this.original = band.original;
        }
    }

    setStatus(band){
        var statuses = {
            Copied: "default",
            Free: "success",
            Variable: "warning",
            Fixed: "danger",
        };
        return statuses[band.type];
    }
}
