import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { PowerTariffService } from "../../services/customer/powerTariff.service";
import { ListService } from "../../services/customer/list.service";
import { ToastrService } from "ngx-toastr";
import { PowerTariff } from "../../models/powerTariff.model";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-create-list',
  templateUrl: './create-list.component.html',
  styleUrls: ['./create-list.component.sass'],
  providers: [DatePipe]
})
export class CreateListComponent implements OnInit {

  @Output() close = new EventEmitter();
  @Output() refreshLists = new EventEmitter();

  listForm: FormGroup;
  powerTariffs: PowerTariff[];

  constructor(
    private listService: ListService,
    private powerTariffService: PowerTariffService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public datePipe: DatePipe
  ) {
    this.listForm = this.fb.group({
      'name': new FormControl('', Validators.required),
      'tariff': new FormControl('', [Validators.required]),
      'valid_from': new FormControl(''),
      'valid_to': new FormControl(''),
    }, {validators: this.dateLessThan('valid_from', 'valid_to')});
  }

  ngOnInit(): void {
    this.setPowerTariffs();
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): {[key: string]: any} => {
      const f = group.controls[from];
      const t = group.controls[to];
      if (f.value > t.value) {
        return {
          dates: "Date from should be less than Date to"
        };
      }
      return {};
    };
  }

  setPowerTariffs() {
    return this.powerTariffService
      .getPowerTariffs()
      .subscribe((powerTariffs) => {
        this.powerTariffs = powerTariffs;
      });
  }

  onSubmit(data: []) {
    const params = {
      name: data['name'],
      customer_id: 0,
      list_type: 1,
      tariff_table_id: data['tariff'].id,
      valid_from: this.datePipe.transform(data['valid_from'], 'yyyy-MM-dd'),
      valid_to: this.datePipe.transform(data['valid_to'], 'yyyy-MM-dd'),
    };

    if (!params['valid_from']) {
      delete params.valid_from;
    }
    if (!params['valid_to']) {
      delete params.valid_to;
    }

    return this.listService.createList(params).subscribe(() => {
      this.refreshLists.emit();
      this.toastr.success('List has been created successfully');
      this.close.emit();
    });
  }

  onCancel() {
    this.close.emit();
  }
}
