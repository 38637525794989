import {Component, Output, EventEmitter, OnInit, Input} from '@angular/core';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {ChargeGroupService} from "../../../../services/customer/chargeGroup.service";
import {PowerTariffService} from "../../../../services/customer/powerTariff.service";
import {PowerTariff} from "../../../../models/powerTariff.model";
import {ChargeGroup} from "../../../../models/chargeGroup.model";
import {TariffService} from "../../../../services/customer/tariff.service";
import {Tariff} from "../../../../models/tariff.model";
import {User} from "../../../../models/user.model";

@Component({
  selector: 'app-edit-charge-group',
  templateUrl: './edit-charge-group.component.html',
  styleUrls: ['./edit-charge-group.component.sass']
})

export class EditChargeGroupComponent implements OnInit {

  @Input() user: User;
  @Input() chargeGroup: ChargeGroup;
  @Output() close = new EventEmitter();
  @Output() refreshChargeGroups = new EventEmitter();

  chargeGroupForm: FormGroup;
  powerTariffs: PowerTariff[];
  timeTariffs: Tariff[];
  originalData: [];

  constructor(
    private chargeGroupService: ChargeGroupService,
    private powerTariffService: PowerTariffService,
    private tariffService: TariffService,
    private fb: FormBuilder, private toastr: ToastrService
  ) {
    this.chargeGroupForm = this.fb.group({
      'name': new FormControl('', Validators.required),
      'tariff_type': new FormControl('', [Validators.required]),
      'default_tariff': new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    const self = this;
      self.powerTariffService.getPowerTariffs().subscribe(powerTariffs => {
        self.powerTariffs = powerTariffs;
        self.setOriginalData();
      });
  }

  setOriginalData() {
    this.chargeGroupService.getChargeGroup(this.chargeGroup).subscribe(data => {
      this.originalData = data;

      if (data['default_time_tariff_id']) {
        const selectedTariff = this.timeTariffs.find(one => {
          return one.tariff_id === this.originalData['default_time_tariff_id'];
        });
        setTimeout(() => {
          this.chargeGroupForm.get('tariff_type').setValue('time');
          this.chargeGroupForm.get('default_tariff').setValue(selectedTariff);
        }, 0)
      }

      if (data['default_power_tariff_id']) {
        const selectedTariff = this.powerTariffs.find(one => {
          return one.id === this.originalData['default_power_tariff_id'];
        });
        setTimeout(() => {
          this.chargeGroupForm.get('tariff_type').setValue('power');
          this.chargeGroupForm.get('default_tariff').setValue(selectedTariff);
        }, 0)
      }

      setTimeout(() => {
        this.chargeGroupForm.get('name').setValue(data['name']);
      }, 0)
    });
  }

  onSubmit(data: []) {
    const params = {
      customer_id: data['default_tariff'].customerId,
      default_power_tariff_id: data['tariff_type'] === 'power' ? data['default_tariff'].id : null,
      default_time_tariff_id: data['tariff_type'] === 'time' ? data['default_tariff'].tariff_id : null,
      name: data['name']
    };

    if (!params.default_power_tariff_id) {
      delete params.default_power_tariff_id;
    }
    if (!params.default_time_tariff_id) {
      delete params.default_time_tariff_id;
    }
    return this.chargeGroupService.editChargeGroup(this.chargeGroup, params).subscribe(() => {
      this.refreshChargeGroups.emit();
      this.toastr.success('Charge group has been updated successfully');
      this.close.emit();
    });
  }

  onCancel() {
    this.close.emit();
  }
}
