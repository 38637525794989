import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PowerTariff } from "../../../../models/powerTariff.model";
import { PriceSchedule } from "../../../../models/priceSchedule.model";
import { PrimengService } from "../../../../services/primeng.service";
import { AuthService } from "../../../../services/auth.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { PowerTariffService } from "../../../../services/customer/powerTariff.service";
import { User } from "../../../../models/user.model";
import { Constants } from "../../../../constants/app-constants";
import { isString } from "util";

@Component({
  selector: 'app-create-price-schedule',
  templateUrl: './create-price-schedule.component.html',
  styleUrls: ['./create-price-schedule.component.sass']
})
export class CreatePriceScheduleComponent implements OnInit {


  @Input() powerTariff: PowerTariff;
  @Input() priceSchedule: PriceSchedule;
  @Input() day;

  @Output() cancelAddSchedule = new EventEmitter();
  @Output() priceScheduleAdded = new EventEmitter();
  @Output() refreshBands = new EventEmitter();

  //user: User;
  priceScheduleForm: FormGroup;
  days: any[];
  newPriceSchedule: any[];

  constructor(
    public primeng: PrimengService,
    public powerTariffService: PowerTariffService,
    public auth: AuthService,
    private fb: FormBuilder,
  ) {
    this.newPriceSchedule = [];
    this.days = Constants.days;
    this.priceScheduleForm = this.fb.group({
      'start_time': new FormControl('00:00:00', Validators.required),
      'end_time': new FormControl('23:59:59', Validators.required),
      'dc_kwh_price': new FormControl(null, Validators.required),
      'ac_kwh_price': new FormControl(null, Validators.required),
      'dc_overstay_fee': new FormControl(null),
      'ac_overstay_fee': new FormControl(null),
      'dc_apply_overstay_per_minute': new FormControl(null),
      'ac_apply_overstay_per_minute': new FormControl(null),
    });
  }

  ngOnInit() {

    setTimeout(()=>{
      this.priceScheduleForm.get('dc_kwh_price').setValue(0);
      this.priceScheduleForm.get('ac_kwh_price').setValue(0);
      if(this.priceSchedule.start_time){
        this.priceScheduleForm.get('start_time').setValue(this.priceSchedule.start_time);
      }
      if(this.priceSchedule.end_time){
        this.priceScheduleForm.get('end_time').setValue(this.priceSchedule.end_time);
      }
      if(this.priceSchedule.dc_overstay_fee){
        this.priceScheduleForm.get('dc_overstay_fee').setValue(this.priceSchedule.dc_overstay_fee);
      }
      if(this.priceSchedule.dc_apply_overstay_per_minute){
        this.priceScheduleForm.get('dc_apply_overstay_per_minute').setValue(this.priceSchedule.dc_apply_overstay_per_minute);
      }
      if(this.priceSchedule.ac_overstay_fee){
        this.priceScheduleForm.get('ac_overstay_fee').setValue(this.priceSchedule.ac_overstay_fee);
      }
      if(this.priceSchedule.ac_apply_overstay_per_minute){
        this.priceScheduleForm.get('ac_apply_overstay_per_minute').setValue(this.priceSchedule.ac_apply_overstay_per_minute);
      }
    },0);
  }

  onCancel() {
    this.cancelAddSchedule.emit();
  }

  onSubmit(data) {
    let _this = this;
    data.power_tariff_id = _this.powerTariff.id;

    _this.days.forEach(day => {
      data[day.key] = (_this.day.key == day.key) ? true : false;
    });

    if(!isString(data.start_time)){
      data.start_time = this.prepareTimeInput(data.start_time);
    }

    if(!isString(data.end_time)){
      data.end_time = this.prepareTimeInput(data.end_time);
    }

    data.dc_apply_overstay_per_minute = data['dc_apply_overstay_per_minute'] || 0;
    data.ac_apply_overstay_per_minute = data['ac_apply_overstay_per_minute'] || 0;

    return this.powerTariffService.addNewPriceSchedule(data).subscribe(response => {
      this.priceScheduleAdded.emit();
      this.refreshBands.emit();
    });
  }

  prepareTimeInput(time: Date): string{
    let dt = new Date(time);
    return `${(dt.getHours() < 10 ? '0' : '') + dt.getHours()}:` +
      `${(dt.getMinutes() < 10 ? '0' : '')+dt.getMinutes()}:` +
      `${(dt.getSeconds() < 10 ? '0' : '')+dt.getSeconds()}`;
  }

}
