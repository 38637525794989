export class ChargingPoint {
  id: number;
  name: string;
  chargeGroupId: number;
  ipAddress: string;

  constructor(chargingPoint) {
    if (chargingPoint) {
      this.id = chargingPoint.id;
      this.name = chargingPoint.name;
      this.chargeGroupId = chargingPoint.charge_group_id;
      this.ipAddress = chargingPoint.ip_address || '';
    }
  }
}
