import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PowerTariff } from "../../../models/powerTariff.model";
import { PrimengService } from "../../../services/primeng.service";
import { ChargeGroup } from "../../../models/chargeGroup.model";
import { User } from "../../../models/user.model";
import { PowerTariffService } from "../../../services/customer/powerTariff.service";

@Component({
  selector: "app-power-tariffs-list",
  templateUrl: "./power-tariffs-list.component.html",
  styleUrls: ["./power-tariffs-list.component.sass"],
})
export class PowerTariffsListComponent implements OnInit {
  @Input() user: User;
  @Output() viewChargeGroups = new EventEmitter();

  powerTariffs: PowerTariff[] = [];
  createPowerTariff: boolean;
  selectedPowerTariff: PowerTariff;
  editPowerTariff: boolean;
  deletePowerTariff: boolean;
  loaded: boolean;

  constructor(
    public primeng: PrimengService,
    public powerTariffService: PowerTariffService
  ) {}

  ngOnInit(): void {
    this.setPowerTariffs();
  }

  create() {
    this.createPowerTariff = !this.createPowerTariff;
  }

  getStatusKey(status: "default" | "active" | "scheduled") {
    if (status === "default") {
      return 1;
    }

    if (status === "active") {
      return 2;
    }

    if (status === "scheduled") {
      return 3;
    }
  }

  sortByStatus(chargeGroups: ChargeGroup[]) {
    const self = this;
    return chargeGroups.sort(function (a, b) {
      return self.getStatusKey(a.status) - self.getStatusKey(b.status);
    });
  }

  isDefaultOrActiveForAnyChargeGroup(powerTariff: PowerTariff) {
    return powerTariff.chargeGroups.find((one) => {
      return one.status === "default" || one.status === "active";
    });
  }

  selectPowerTariff(powerTariff: PowerTariff): void {
    this.selectedPowerTariff = powerTariff;
  }

  setPowerTariffs() {
    return this.powerTariffService
      .getPowerTariffs()
      .subscribe((powerTariffs) => {
        this.powerTariffs = powerTariffs;
        this.loaded = false;
      });
  }

  refreshTariffs() {
    this.powerTariffs = [];
    this.setPowerTariffs();
  }
}
