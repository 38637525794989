import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ChargeGroup } from "../../../../models/chargeGroup.model";
import { LinkedTariff } from "../../../../models/linkedTariff.model";
import { LinkedTariffService } from "../../../../services/customer/linkedTariff.service";
import { PrimengService } from "../../../../services/primeng.service";

@Component({
  selector: "app-linked-tariffs-list",
  templateUrl: "./linked-tariffs-list.component.html",
  styleUrls: ["./linked-tariffs-list.component.sass"],
})
export class LinkedTariffsListComponent implements OnInit {
  @Input() chargeGroup: ChargeGroup;
  @Output() close = new EventEmitter();
  @Output() refreshChargeGroups = new EventEmitter();

  linkedTariffs: LinkedTariff[] = [];
  selectedLinkedTariff: LinkedTariff;
  createLinkedTariff: boolean;
  editLinkedTariff: boolean;
  deleteLinkedTariff: boolean;
  loaded: boolean;

  constructor(
    public linkedTariffService: LinkedTariffService,
    public primeng: PrimengService
  ) {}

  ngOnInit(): void {
    this.setLinkedTariffs();
  }

  setLinkedTariff(linkedTariff: LinkedTariff): void {
    this.selectedLinkedTariff = linkedTariff;
  }

  setLinkedTariffs(): void {
    this.linkedTariffService
      .getLinkedTariffs(this.chargeGroup)
      .subscribe((linkedTariffs) => {
        this.linkedTariffs = linkedTariffs;
        this.loaded = true;
      });
  }

  refreshLinkedTariffs(): void {
    this.linkedTariffs = [];
    this.setLinkedTariffs();
    this.refreshChargeGroups.emit();
  }

  onCancel() {
    this.close.emit();
  }
}
