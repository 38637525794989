import { Component, OnInit, ɵConsole } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  user: User;
  
  veriparkUrl : string = environment.veriparkUrl;

  constructor(
  ) {}

  ngOnInit() {
  }
}
