export class User {
    id: string;
    username: string;
    email: string;
    person_id: string;
    customer_id: number;
    login_attempts: number;
    is_default: boolean;
    allow_marketing: boolean;
    default_language: string;
    site_registered: string;
    archived: boolean;
    created_at: string;
    updated_at: string;
    isSuperAdmin: boolean = false;

    constructor(user) {
        if (user) {
            this.id = user.data.id;
            this.username = user.data.username;
            this.email = user.data.email;
            this.person_id = user.data.person_id;
            this.customer_id = user.data.customer_id;
            this.login_attempts = user.data.login_attempts;
            this.is_default = user.data.is_default;
            this.allow_marketing = user.data.allow_marketing;
            this.default_language = user.data.default_language;
            this.site_registered = user.data.site_registered;
            this.archived = user.data.archived;
            this.created_at = user.data.created_at;
            this.updated_at = user.data.updated_at;
            var superAdminRole = user.data.roles.find(one => {
                return one.id === 2;
            });

            this.isSuperAdmin = superAdminRole ? true : false;
        }
    }
}
