import { Injectable } from '@angular/core';
import { Table } from 'primeng/table';

@Injectable({
  providedIn: 'root'
})
export class PrimengService {

  constructor() { }

  viewFilterMessage(dt: Table): string {
    let maxViewed = ((dt.first / dt.rows) + 1) * dt.rows,
    to = ((maxViewed) > dt.totalRecords ? dt.totalRecords : maxViewed);
    
    return `Showing ${dt.first + 1} to ${to} of ${dt.totalRecords} entries`;
  }
}
