import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {PowerTariffService} from "../../../services/customer/powerTariff.service";
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-create-power-tariff',
  templateUrl: './create-power-tariff.component.html',
  styleUrls: ['./create-power-tariff.component.sass']
})

export class CreatePowerTariffComponent implements OnInit {

  @Output() close = new EventEmitter();
  @Output() refreshTariffs = new EventEmitter();

  tariffForm: FormGroup;

  constructor(public powerTariffService: PowerTariffService, private fb: FormBuilder, private toastr: ToastrService) {
    this.tariffForm = this.fb.group({
      'name': new FormControl('', Validators.required),
      'dc_kwh_price': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'dc_connection_fee': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'dc_minimum_price': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'dc_maximum_price': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'dc_connection_fee_on_top_of_minimum_price': new FormControl(''),
      'dc_overstay_fee': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'dc_overstay_cap': new FormControl('', [Validators.pattern('^[0-9]*$')]),
      'dc_apply_overstay_per_minute': new FormControl(''),
      'dc_overstay_interval': new FormControl('', [Validators.pattern('^[0-9]*$')]),
      'dc_maximum_charging_session_length_in_minutes': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'ac_kwh_price': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'ac_connection_fee': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'ac_minimum_price': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'ac_maximum_price': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'ac_connection_fee_on_top_of_minimum_price': new FormControl(''),
      'ac_overstay_fee': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'ac_apply_overstay_per_minute': new FormControl(''),
      'ac_overstay_interval': new FormControl('', [Validators.pattern('^[0-9]*$')]),
      'ac_maximum_charging_session_length_in_minutes': new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      'ac_overstay_cap': new FormControl('', [Validators.pattern('^[0-9]*$')]),
      'price_description': new FormControl('')
    });
  }

  ngOnInit() {
    setTimeout(() => {
      this.tariffForm.get('dc_connection_fee_on_top_of_minimum_price').setValue(false);
    }, 0)
  }

  onSubmit(data) {
    return this.powerTariffService.addPowerTariff(data).subscribe(response => {
      this.refreshTariffs.emit();
      this.toastr.success('Power tariff has been created successfully');
      this.close.emit();
    });
  }

  onCancel() {
    this.close.emit();
  }
}
