import { CarPark } from './carpark.model';

export class Tariff {
    tariff_id: number;
    tariff_name: string;
    max_stay: number;
    customerId: number;
    time_rule: any[];
    carParks: CarPark[];
    carparksNames: [];

    constructor(tariff) {
        if (tariff) {
            this.tariff_id = tariff.tariff_id;
            this.max_stay = tariff.max_stay;
            this.tariff_name = tariff.tariff_name;
            this.time_rule = tariff.time_rule;
            this.customerId = tariff.customer_id ? tariff.customer_id : 0;
            this.time_rule['awaiting_period'] = tariff.time_rule['awaiting_period'] ? tariff.time_rule['awaiting_period'] : 0;
            this.time_rule['awaiting_reset_midnight'] = tariff.time_rule['awaiting_reset_midnight'] ? 1 : 0;
            this.time_rule['culminating_period'] = tariff.time_rule['culminating_period'] ? tariff.time_rule['culminating_period'] : 0;
            this.time_rule['culminating_reset_midnight'] = tariff.time_rule['culminating_reset_midnight'] ? 1: 0;
            this.carParks = tariff.carparks ? tariff.carparks.map(value => new CarPark(value)) : null;
            this.carparksNames = tariff.carparks ? tariff.carparks.map(carpark => {
                return carpark.carpark_name;
            }) : [];
        }
    }
}
