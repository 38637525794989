import {ChargeGroup} from "./chargeGroup.model";

export class PowerTariff {
  id: number;
  name: string;
  customerId: string;
  status: string;
  chargeGroups: ChargeGroup[];
  chargeGroupsNames: [];

  constructor(powerTariff) {
    if (powerTariff) {
      this.id = powerTariff.id;
      this.customerId = powerTariff.customer_id;
      this.name = powerTariff.name;
      this.status = powerTariff.status ? powerTariff.status : null;
      this.chargeGroups = powerTariff.charge_groups ? powerTariff.charge_groups.map(one => new ChargeGroup(one)) : [];
      this.chargeGroupsNames = powerTariff.charge_groups ? powerTariff.charge_groups.map(group => {
        return group.name;
      }) : [];
    }
  }
}
